import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function ViewerPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AnnualBudget');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.BudgetPeriod.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AnnualBudget.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AnnualBudgetLine.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/AnnualBudget/IndexViewer.custom.js',
        ],
        'initSearchIndexViewer',
        {
          canEdit: true,
          canCreate: true,
          canRemove: true,
          mode: 'AnnualBudget',
          appSettings: currentSecurity.profile.AppSettings.Values,
        },
      );
    }
  }, []);
  return (
    <div className="panelcontent-section" style={{ display: 'none' }}>
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>Quarterly Budget</h3>
          <div className="field fullwidth-container">
            <label className="required">Broadcast Year</label>
            <div style={{ width: '100%', display: 'flex' }}>
              <input id="inputPreviousYear" style={{ width: '48%', marginRight: '10px' }} />
              <input id="inputYear" style={{ width: '48%', float: 'right' }} />
            </div>
          </div>
          <div className="field fullwidth-container">
            <label className="required">Agency</label>
            <input id="inputAgency" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Client</label>
            <input id="inputClient" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Budget Type</label>
            <input id="inputBudgetType" />
          </div>
          <div className="field fullwidth-container">
            <label>Hispanic</label>
            <input id="inputHispanic" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Media Networks</label>
            <input id="inputMediaNetwork" />
          </div>
          <div className="field fullwidth-container">
            <label>Distribution Types</label>
            <input id="inputDistributionType" />
          </div>
          <div className="field fullwidth-container">
            <label>Day Parts</label>
            <input id="inputDayPart" />
          </div>
          <section className="button-toolbar">
            <div className="right">
              <button id="applyFilterButton" type="button" className="k-button k-primary" title="Go">
                Go
              </button>
            </div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars hidden" id="contentBar"></i>
        <div id="tabstrip">
          <ul>
            <li className="k-active">At a glance</li>
            <li>Sports and Specials</li>
            <li>Proprietary Products</li>
          </ul>
          <div>
            <div className="container-fluid">
              <div className="row" style={{ marginBottom: '4em' }}>
                <div className="col-md-4">
                  <div id="totalByAgencyChart"></div>
                </div>
                <div className="col-md-4">
                  <div id="totalByDistributionChart"></div>
                </div>
                <div className="col-md-4">
                  <div id="totalByTypeChart"></div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: '4em' }}>
                <div className="col-md-8">
                  <div id="spendByDaypart"></div>
                </div>
                <div className="col-md-4">
                  <div id="totalByDaypart"></div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: '4em' }}>
                <div className="col-md-12">
                  <div id="stackByDistributionType"></div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: '4em' }}>
                <div className="col-md-12">
                  <div id="stackByAgencyNetwork" style={{ height: '900px' }}></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container-fluid">
              <div className="row" style={{ marginBottom: '4em' }}>
                <div className="col-md-4">
                  <div id="totalBySports"></div>
                </div>
                <div className="col-md-8">
                  <div id="spendBySports"></div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: '4em' }}>
                <div className="col-md-12">
                  <div id="stackBySports" style={{ height: '400px' }}></div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: '4em' }}>
                <div className="col-md-12">
                  <div id="totalbyCPM" style={{ height: '400px' }}></div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: '4em' }}>
                <div className="col-md-12">
                  <div id="totalbyBudget" style={{ height: '500px' }}></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div id="stackByAgencyClient" style={{ height: '800px' }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
