import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingDealCommitmentCustomNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingDealCommitment>({ Id: '', LoadingInfo: {}, Sequence: '0' });
  const currentSecurity = useSecurity('Trading', 'DealCommitment');
  const updatedState: ITradingDealCommitment = { Id: '', LoadingInfo: {}, Sequence: '0' };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.DealYear = new Date().getFullYear().toString();
      updatedState.StartDate = new Date().toString();
      updatedState.EndDate = new Date().toString();
      const date = new Date();
      const startDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
      const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 3, -1));
      updatedState.StartDate = startDate.toISOString();
      updatedState.EndDate = endDate.toISOString();
      updatedState.ContractStatus = 5;
      updatedState.Type = 1;
      updatedState.OptionType = 0;
      updatedState.CommitmentAmount = 0;
      updatedState.InventoryAmount = 0;
      updatedState.TargetVariance = 0;
      updatedState.InventoryVariance = 0;

      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.DealCommitment.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/DealCommitment/View.custom.js',
        ],
        'initNewTradingDealCommitment',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DealCommitment" className="controls-container default block">
              <h3>
                Create a New <b>Deal Commitments</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DealCommitmentCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DealCommitmentLabelBlockedCode">Blocked</label>
                  <input
                    id="DealCommitmentCheckBlockedCode"
                    defaultChecked
                    name="DealCommitmentCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Contracted Parties</div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="DealCommitmentType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                  >
                    <option value="1">Upfront Broadcast</option>
                    <option value="2">Upfront Calendar</option>
                    <option value="3">Scatter</option>
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaNetworkProfileId" className="required">
                    Media Network Profile
                  </label>
                  <select
                    className=""
                    id="DealCommitmentMediaNetworkProfileId"
                    name="MediaNetworkProfileId"
                    defaultValue={state.MediaNetworkProfileId}
                    required
                    data-required-msg="Media Network Profile is required"
                  >
                    {state.MediaNetworkProfileId && (
                      <option value={state.MediaNetworkProfileId}>{state.MediaNetworkProfileDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="DistributionTypeId" className="required">
                    Media Type
                  </label>
                  <select
                    className=""
                    id="DealCommitmentDistributionTypeId"
                    name="DistributionTypeId"
                    defaultValue={state.DistributionTypeId}
                    required
                    data-required-msg="Distribution Type is required"
                  >
                    {state.DistributionTypeId && (
                      <option value={state.DistributionTypeId}>{state.DistributionTypeDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="DayPartId">Daypart</label>
                  <select className="" id="DealCommitmentDayPartId" name="DayPartId" defaultValue={state.DayPartId}>
                    {state.DayPartId && <option value={state.DayPartId}>{state.DayPartDisplayValue}</option>}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="OptionType" className="required">
                    Options Type
                  </label>
                  <select
                    className=""
                    id="DealCommitmentOptionType"
                    name="OptionType"
                    value={state.OptionType}
                    required
                    data-required-msg="Options Type is required"
                  >
                    <option value="0">ROS</option>
                    <option value="1">Sponsorships</option>
                    <option value="2">Sports</option>
                    <option value="3">Others</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="DealName">Deal Name</label>
                  <input
                    required
                    data-required-msg="Deal Name is required"
                    id="DealCommitmentDealName"
                    name="DealName"
                    type="text"
                    defaultValue={state.DealName}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="content-header">Fulfillment Period & Attribution</div>
                <div className="col-md-2">
                  <label htmlFor="DealYear">Deal Year</label>
                  <input
                    required
                    data-required-msg="Deal Year is required"
                    data-val-number="The field Deal Year must be a number."
                    id="DealCommitmentDealYear"
                    name="DealYear"
                    type="text"
                    defaultValue={state.DealYear}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="StartDate">Start Date</label>
                  <input
                    required
                    data-required-msg="Start Date is required"
                    id="DealCommitmentStartDate"
                    name="StartDate"
                    type="text"
                    defaultValue={state.StartDate}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="EndDate">End Date</label>
                  <input
                    required
                    data-required-msg="End Date is required"
                    id="DealCommitmentEndDate"
                    name="EndDate"
                    type="text"
                    defaultValue={state.EndDate}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="Sequence" className="required">
                    Sequence
                  </label>
                  <input
                    data-required-msg="Sequence is required"
                    data-val="true"
                    data-val-number="The field Sequence must be a number."
                    defaultValue={state.Sequence}
                    data-val-required="The Sequence field is required."
                    id="DealCommitmentSequence"
                    min="-2147483648"
                    name="Sequence"
                    required
                    type="text"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="ContractStatus" className="required">
                    Deal Status
                  </label>
                  <select
                    className=""
                    id="DealCommitmentContractStatus"
                    name="ContractStatus"
                    value={state.ContractStatus}
                    required
                    data-required-msg="Deal Status is required"
                  >
                    <option value="0">Under Negotiation</option>
                    <option value="1">Negotiation Complete</option>
                    <option value="2">Contract Signed</option>
                    <option value="3">Cancelled</option>
                    <option value="4">Dummy</option>
                    <option value="5">Effective</option>
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="DealReference">#Reference</label>
                  <input
                    id="DealCommitmentDealReference"
                    name="DealReference"
                    type="text"
                    defaultValue={state.DealReference}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Comments">Comments</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="DealCommitmentComments"
                      name="Comments"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Comments}
                    ></textarea>
                  </div>
                </div>
                <div className="content-header">Target Definition</div>
                <div className="col-md-2">
                  <label htmlFor="CommitmentAmount">Commitment Amount</label>
                  <input
                    required
                    data-required-msg="Commitment Amount is required"
                    data-val-number="The field Commitment Amount must be a number."
                    id="DealCommitmentCommitmentAmount"
                    name="CommitmentAmount"
                    type="text"
                    defaultValue={state.CommitmentAmount}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="InventoryAmount">Inventory Amount</label>
                  <input
                    data-val-number="The field Inventory Amount must be a number."
                    id="DealCommitmentInventoryAmount"
                    name="InventoryAmount"
                    type="text"
                    defaultValue={state.InventoryAmount}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="clear"></div>

                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DealCommitmentSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarDealCommitment">
                <div className="left">
                  <button id="cancelDealCommitmentButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDealCommitmentButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingDealCommitment {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  Sequence?: string;
  DayPartId?: string;
  DayPartDisplayValue?: string;
  DistributionTypeId?: string;
  DistributionTypeDisplayValue?: string;
  MediaNetworkProfileId?: string;
  MediaNetworkProfileDisplayValue?: string;
  Comments?: string;
  DealName?: string;
  DealReference?: string;
  ContractStatus?: number;
  OptionType?: number;
  Type?: number;
  StartDate?: string;
  EndDate?: string;
  CommitmentAmount?: number;
  DealYear?: string;
  InventoryAmount?: number;
  InventoryVariance?: number;
  TargetVariance?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealContractStatusEnum {
  'Under Negotiation' = 0,
  'Negotiation Complete' = 1,
  'Contract Signed' = 2,
  'Cancelled' = 3,
  'Dummy' = 4,
  'Effective' = 5,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealTypeEnum {
  'Media Owner' = 0,
  'Upfront Broadcast' = 1,
  'Upfront Calendar' = 2,
  'Scatter' = 3,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealTrackerTypeEnum {
  'ROS' = 0,
  'Sponsorships' = 1,
  'Sports' = 2,
  'Others' = 3,
}

export function TradingDealCommitmentCustomDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingDealCommitment>({ Id: '', LoadingInfo: {}, Sequence: '0' });
  const currentSecurity = useSecurity('Trading', 'DealCommitment');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'DealCommitment', id).then((result) => {
        setState(result.Value[0] as ITradingDealCommitment);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.DealCommitment.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/DealCommitment/View.custom.js',
          ],
          'initDetailTradingDealCommitment',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingDealCommitmentState'] = (data) => {
    setState(data.Value[0] as ITradingDealCommitment);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DealCommitment" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Deal Commitments: <span>{state.Display}</span>
                      <span
                        id="DealCommitmentToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.DayPartId && (
                    <span className="element">
                      <label>
                        Daypart: <label className="strong">{state.DayPartDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
                <div className="content-line">
                  {state.DistributionTypeId && (
                    <span className="element">
                      <label>
                        Media Type: <label className="strong">{state.DistributionTypeDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MediaNetworkProfileId && (
                    <span className="element">
                      <label>
                        Media Network Profile: <label className="strong">{state.MediaNetworkProfileDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  <span className="element">
                    <label>
                      Deal Name: <label className="strong">{state.DealName}</label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      #Reference: <label className="strong">{state.DealReference}</label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Deal Status:{' '}
                      <label className="strong">
                        {state.ContractStatus != undefined ? DealContractStatusEnum[state.ContractStatus] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Start Date: <label className="strong">{formatDate(state.StartDate)}</label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      End Date: <label className="strong">{formatDate(state.EndDate)}</label>
                    </label>
                  </span>

                  <span className="element">
                    <label>
                      Commitment Amount: <label className="strong">{state.CommitmentAmount}</label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      Deal Year: <label className="strong">{state.DealYear}</label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Inventory Amount: <label className="strong">{state.InventoryAmount}</label>
                    </label>
                  </span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DealCommitmentCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DealCommitmentLabelBlockedCode">Blocked</label>
                  <input
                    id="DealCommitmentCheckBlockedCode"
                    defaultChecked
                    name="DealCommitmentCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Contracted Parties</div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="DealCommitmentType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                  >
                    <option value="1">Upfront Broadcast</option>
                    <option value="2">Upfront Calendar</option>
                    <option value="3">Scatter</option>
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaNetworkProfileId" className="required">
                    Media Network Profile
                  </label>
                  <select
                    className=""
                    id="DealCommitmentMediaNetworkProfileId"
                    name="MediaNetworkProfileId"
                    defaultValue={state.MediaNetworkProfileId}
                    required
                    data-required-msg="Media Network Profile is required"
                  >
                    {state.MediaNetworkProfileId && (
                      <option value={state.MediaNetworkProfileId}>{state.MediaNetworkProfileDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="DistributionTypeId" className="required">
                    Media Type
                  </label>
                  <select
                    className=""
                    id="DealCommitmentDistributionTypeId"
                    name="DistributionTypeId"
                    defaultValue={state.DistributionTypeId}
                    required
                    data-required-msg="Distribution Type is required"
                  >
                    {state.DistributionTypeId && (
                      <option value={state.DistributionTypeId}>{state.DistributionTypeDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="DayPartId">Daypart</label>
                  <select className="" id="DealCommitmentDayPartId" name="DayPartId" defaultValue={state.DayPartId}>
                    {state.DayPartId && <option value={state.DayPartId}>{state.DayPartDisplayValue}</option>}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="OptionType" className="required">
                    Options Type
                  </label>
                  <select
                    className=""
                    id="DealCommitmentOptionType"
                    name="OptionType"
                    value={state.OptionType}
                    required
                    data-required-msg="Options Type is required"
                  >
                    <option value="0">ROS</option>
                    <option value="1">Sponsorships</option>
                    <option value="2">Sports</option>
                    <option value="3">Others</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="DealName">Deal Name</label>
                  <input
                    required
                    data-required-msg="Deal Name is required"
                    id="DealCommitmentDealName"
                    name="DealName"
                    type="text"
                    defaultValue={state.DealName}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="content-header">Fulfillment Period & Attribution</div>
                <div className="col-md-2">
                  <label htmlFor="DealYear">Deal Year</label>
                  <input
                    required
                    data-required-msg="Deal Year is required"
                    data-val-number="The field Deal Year must be a number."
                    id="DealCommitmentDealYear"
                    name="DealYear"
                    type="text"
                    defaultValue={state.DealYear}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="StartDate">Start Date</label>
                  <input
                    required
                    data-required-msg="Start Date is required"
                    id="DealCommitmentStartDate"
                    name="StartDate"
                    type="text"
                    defaultValue={state.StartDate}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="EndDate">End Date</label>
                  <input
                    required
                    data-required-msg="End Date is required"
                    id="DealCommitmentEndDate"
                    name="EndDate"
                    type="text"
                    defaultValue={state.EndDate}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="Sequence" className="required">
                    Sequence
                  </label>
                  <input
                    data-required-msg="Sequence is required"
                    data-val="true"
                    data-val-number="The field Sequence must be a number."
                    defaultValue={state.Sequence}
                    data-val-required="The Sequence field is required."
                    id="DealCommitmentSequence"
                    min="-2147483648"
                    name="Sequence"
                    required
                    type="text"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="ContractStatus" className="required">
                    Deal Status
                  </label>
                  <select
                    className=""
                    id="DealCommitmentContractStatus"
                    name="ContractStatus"
                    value={state.ContractStatus}
                    required
                    data-required-msg="Deal Status is required"
                  >
                    <option value="0">Under Negotiation</option>
                    <option value="1">Negotiation Complete</option>
                    <option value="2">Contract Signed</option>
                    <option value="3">Cancelled</option>
                    <option value="4">Dummy</option>
                    <option value="5">Effective</option>
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="DealReference">#Reference</label>
                  <input
                    id="DealCommitmentDealReference"
                    name="DealReference"
                    type="text"
                    defaultValue={state.DealReference}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Comments">Comments</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="DealCommitmentComments"
                      name="Comments"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Comments}
                    ></textarea>
                  </div>
                </div>
                <div className="content-header">Target Definition</div>
                <div className="col-md-2">
                  <label htmlFor="CommitmentAmount">Commitment Amount</label>
                  <input
                    required
                    data-required-msg="Commitment Amount is required"
                    data-val-number="The field Commitment Amount must be a number."
                    id="DealCommitmentCommitmentAmount"
                    name="CommitmentAmount"
                    type="text"
                    defaultValue={state.CommitmentAmount}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="InventoryAmount">Inventory Amount</label>
                  <input
                    data-val-number="The field Inventory Amount must be a number."
                    id="DealCommitmentInventoryAmount"
                    name="InventoryAmount"
                    type="text"
                    defaultValue={state.InventoryAmount}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="clear"></div>

                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DealCommitmentSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="DealCommitmentSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="DealCommitmentSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarDealCommitment">
                <div className="left">
                  <button id="cancelDealCommitmentButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDealCommitmentButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
