import React from 'react';
import { RootState } from '../../store/reducer/rootReducer';
import Footer from '../shared/Footer';
import JumboBox, { JumboImageBox } from '../shared/JumboBox';
import { Button } from '@progress/kendo-react-buttons';
import { CommonResources, Resources } from '../../config/CommonResources';
import { Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { connect, ConnectedProps } from 'react-redux';
import { IUserInfoProps, IMenuItemProps, ITopMenuItemsProps } from '../services/GraphQLShared';
import WelcomeNoAuthorized from './WelcomeNoAuthorized';
import { sidebar_menu } from '../../config/Menu';
import { useNavigate } from 'react-router-dom';
import { OnDemandIcon } from '../../components/helpers/IconUtils';
import { callJSMethod } from '../helpers/Utils';

const mapState = (state: RootState) => ({
  profile: state.security.profile,
  isAuthorized: state.security.isAuthorized,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type IDashboardProps = PropsFromRedux & {
  isAuthenticated: boolean;
  user: IUserInfoProps;
};

function Dashboard(props: IDashboardProps): React.ReactElement {
  const navigate = useNavigate();
  const [data] = React.useState<IMenuItemProps[]>(sidebar_menu);
  let topMenuItems: ITopMenuItemsProps[] = [];
  if (props.profile) {
    topMenuItems = props.profile.DataAuth.TopMenuItems;
  }
  callJSMethod('hideBreadCrumb', true);
  const handleClick = (box: IMenuItemProps) => {
    navigate(box.route);
  };

  const getColor = (key: string) => {
    return key == 'Master'
      ? 'bg-brightblue'
      : key == 'SecurityMenu' || key == 'IntegrationMenu' || key == 'AdminMenu'
      ? 'bg-orange'
      : 'bg-deepnavy';
  };

  const renderPanels = (
    item: IMenuItemProps,
    panels: IMenuItemProps[],
    perm: ITopMenuItemsProps[],
    styleName: string,
  ) => {
    const data = panels.filter(
      (e) =>
        e.parentId === item.id &&
        perm.some(
          (t) =>
            t.Item1 == '' ||
            ((t.Item1 == item.key || (t.Item1 == 'Framework' && (t.Item2 == item.key || t.Item2 == ''))) &&
              (t.Item3 == '' || t.Item3 == e.key)),
        ),
    );
    return (
      <>
        <Row>
          <Col style={{ display: 'contents' }}>
            <div style={{ width: '95%' }}>
              <h2 className="mb-1">{item.title}</h2>
            </div>
            <div style={{ width: '5%' }}>
              <OnDemandIcon icon={item.icon} size="2x" style={{ float: 'right' }} />
            </div>
            <p>{item.description}</p>
          </Col>
        </Row>
        <Row>
          {data.map((box: IMenuItemProps, i: number) => (
            <Col
              key={i + item.id}
              className="p-2 border border-1 with-pointer"
              style={{ margin: '.3rem 1rem' }}
              onClick={() => handleClick(box)}
            >
              <Card className={`shadow-none text-center ${styleName}`}>
                <CardBody style={{ minWidth: '150px', minHeight: '130px' }}>
                  <OnDemandIcon icon={box.icon != 'tablet-alt' ? box.icon : item.icon} size="4x" />
                  <div>
                    <CardTitle>{box.title}</CardTitle>
                  </div>
                  <p className="d-none d-lg-block" style={{ minHeight: '0' }}>
                    {box.description}
                  </p>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </>
    );
  };
  return props.isAuthorized ? (
    <div className="app__content">
      <div>
        <JumboImageBox>
          <Button
            fillMode={'flat'}
            style={{ float: 'right' }}
            onClick={() => {
              window.open('https://wppit.service-now.com/service/', '_blank');
            }}
          >
            <OnDemandIcon icon={'life-ring'} className="me-2" />
            {CommonResources.Button_ContactSupport}
          </Button>
          <h2 className="mb-0">
            {CommonResources.Hello}{' '}
            <span className="text-outlined">{props.user != null ? props.user.givenName : 'Stranger'}</span>!
          </h2>
          <h4>
            Welcome to <b>{Resources.ApplicationDescription}</b>!
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: `${Resources.ApplicationIntro}<br/> ${Resources.ApplicationContanctTeam}`,
            }}
          ></p>
        </JumboImageBox>
        {data
          .filter(
            (e) =>
              e.key != '' &&
              e.parentId === undefined &&
              topMenuItems.some(
                (t) =>
                  t.Item1 == '' || t.Item1 == e.key || (t.Item1 == 'Framework' && (t.Item2 == e.key || t.Item2 == '')),
              ),
          )
          .map((e: IMenuItemProps, i: number) => (
            <JumboBox key={i} styleName={getColor(e.key) + ' mb-0'}>
              {renderPanels(e, data, topMenuItems, getColor(e.key))}
            </JumboBox>
          ))}
      </div>
      <Footer />
    </div>
  ) : (
    <WelcomeNoAuthorized user={props.user} />
  );
}

export default React.memo(connector(Dashboard));
