import React from 'react';
import { Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { MasterClientPage } from '../components/pages/Master/Client/IndexPage';
import { MasterClientNewPage, MasterClientDetailPage } from '../components/pages/Master/Client/ViewPage';
import { MasterCompanyPage } from '../components/pages/Master/Company/IndexPage';
import { MasterCompanyNewPage, MasterCompanyDetailPage } from '../components/pages/Master/Company/ViewPage';
import { MasterDayPartPage } from '../components/pages/Master/DayPart/IndexPage';
import { MasterDayPartNewPage, MasterDayPartDetailPage } from '../components/pages/Master/DayPart/ViewPage';
import { MasterMediaNetworkPage } from '../components/pages/Master/MediaNetwork/IndexPage';
import { MasterMediaNetworkNewPage, MasterMediaNetworkDetailPage } from '../components/pages/Master/MediaNetwork/ViewPage';
import { MasterAdTypePage } from '../components/pages/Master/AdType/IndexPage';
import { MasterAdTypeNewPage, MasterAdTypeDetailPage } from '../components/pages/Master/AdType/ViewPage';
import { TradingAgencyProfilePage } from '../components/pages/Trading/AgencyProfile/IndexPage';
import { TradingAgencyProfileNewPage, TradingAgencyProfileDetailPage } from '../components/pages/Trading/AgencyProfile/ViewPage';
import { TradingClientProfilePage } from '../components/pages/Trading/ClientProfile/IndexPage';
import { TradingClientProfileNewPage, TradingClientProfileDetailPage } from '../components/pages/Trading/ClientProfile/ViewPage';
import { TradingMediaNetworkProfilePage } from '../components/pages/Trading/MediaNetworkProfile/IndexPage';
import { TradingMediaNetworkProfileNewPage, TradingMediaNetworkProfileDetailPage } from '../components/pages/Trading/MediaNetworkProfile/ViewPage';
import { TradingAnnualBudgetPage } from '../components/pages/Trading/AnnualBudget/IndexPage';
import { TradingAnnualBudgetNewPage, TradingAnnualBudgetDetailPage } from '../components/pages/Trading/AnnualBudget/ViewPage';
import { TradingAnnualBudgetLineChangePage } from '../components/pages/Trading/AnnualBudgetLineChange/IndexPage';
import { TradingAnnualBudgetLineChangeNewPage, TradingAnnualBudgetLineChangeDetailPage } from '../components/pages/Trading/AnnualBudgetLineChange/ViewPage';
import { MasterMasterClientPage } from '../components/pages/Master/MasterClient/IndexPage';
import { MasterMasterClientNewPage, MasterMasterClientDetailPage } from '../components/pages/Master/MasterClient/ViewPage';
import { MasterSectorPage } from '../components/pages/Master/Sector/IndexPage';
import { MasterSectorNewPage, MasterSectorDetailPage } from '../components/pages/Master/Sector/ViewPage';
import { MasterMasterMediaTypePage } from '../components/pages/Master/MasterMediaType/IndexPage';
import { MasterMasterMediaTypeNewPage, MasterMasterMediaTypeDetailPage } from '../components/pages/Master/MasterMediaType/ViewPage';
import { MasterMediaGroupPage } from '../components/pages/Master/MediaGroup/IndexPage';
import { MasterMediaGroupNewPage, MasterMediaGroupDetailPage } from '../components/pages/Master/MediaGroup/ViewPage';
import { MasterAudiencePage } from '../components/pages/Master/Audience/IndexPage';
import { MasterAudienceNewPage, MasterAudienceDetailPage } from '../components/pages/Master/Audience/ViewPage';
import { MasterDistributionTypePage } from '../components/pages/Master/DistributionType/IndexPage';
import { MasterDistributionTypeNewPage, MasterDistributionTypeDetailPage } from '../components/pages/Master/DistributionType/ViewPage';
import { MasterProgramTypePage } from '../components/pages/Master/ProgramType/IndexPage';
import { MasterProgramTypeNewPage, MasterProgramTypeDetailPage } from '../components/pages/Master/ProgramType/ViewPage';
import { MasterInventoryTypePage } from '../components/pages/Master/InventoryType/IndexPage';
import { MasterInventoryTypeNewPage, MasterInventoryTypeDetailPage } from '../components/pages/Master/InventoryType/ViewPage';
import { MasterEventTypePage } from '../components/pages/Master/EventType/IndexPage';
import { MasterEventTypeNewPage, MasterEventTypeDetailPage } from '../components/pages/Master/EventType/ViewPage';
import { MasterAdDurationPage } from '../components/pages/Master/AdDuration/IndexPage';
import { MasterAdDurationNewPage, MasterAdDurationDetailPage } from '../components/pages/Master/AdDuration/ViewPage';
import { MasterMarketPage } from '../components/pages/Master/Market/IndexPage';
import { MasterMarketNewPage, MasterMarketDetailPage } from '../components/pages/Master/Market/ViewPage';
import { TradingDealCommitmentPage } from '../components/pages/Trading/DealCommitment/IndexPage';
import { TradingDealCommitmentNewPage, TradingDealCommitmentDetailPage } from '../components/pages/Trading/DealCommitment/ViewPage';
import { TradingDirectResponseMappingPage } from '../components/pages/Trading/DirectResponseMapping/IndexPage';
import { TradingDirectResponseMappingNewPage, TradingDirectResponseMappingDetailPage } from '../components/pages/Trading/DirectResponseMapping/ViewPage';

export const createRoutes = (isAuthenticated) => (
  <>
	  <Route path="/Master/Client/Index" element={isAuthenticated ? <MasterClientPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Client/New" element={isAuthenticated ? <MasterClientNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Client/:id" element={isAuthenticated ? <MasterClientDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Company/Index" element={isAuthenticated ? <MasterCompanyPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Company/New" element={isAuthenticated ? <MasterCompanyNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Company/:id" element={isAuthenticated ? <MasterCompanyDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/DayPart/Index" element={isAuthenticated ? <MasterDayPartPage /> : <Navigate to="/" />} />
	  <Route path="/Master/DayPart/New" element={isAuthenticated ? <MasterDayPartNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/DayPart/:id" element={isAuthenticated ? <MasterDayPartDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaNetwork/Index" element={isAuthenticated ? <MasterMediaNetworkPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaNetwork/New" element={isAuthenticated ? <MasterMediaNetworkNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaNetwork/:id" element={isAuthenticated ? <MasterMediaNetworkDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/AdType/Index" element={isAuthenticated ? <MasterAdTypePage /> : <Navigate to="/" />} />
	  <Route path="/Master/AdType/New" element={isAuthenticated ? <MasterAdTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/AdType/:id" element={isAuthenticated ? <MasterAdTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfile/Index" element={isAuthenticated ? <TradingAgencyProfilePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfile/New" element={isAuthenticated ? <TradingAgencyProfileNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AgencyProfile/:id" element={isAuthenticated ? <TradingAgencyProfileDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfile/Index" element={isAuthenticated ? <TradingClientProfilePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfile/New" element={isAuthenticated ? <TradingClientProfileNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/ClientProfile/:id" element={isAuthenticated ? <TradingClientProfileDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MediaNetworkProfile/Index" element={isAuthenticated ? <TradingMediaNetworkProfilePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MediaNetworkProfile/New" element={isAuthenticated ? <TradingMediaNetworkProfileNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/MediaNetworkProfile/:id" element={isAuthenticated ? <TradingMediaNetworkProfileDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualBudget/Index" element={isAuthenticated ? <TradingAnnualBudgetPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualBudget/New" element={isAuthenticated ? <TradingAnnualBudgetNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualBudget/:id" element={isAuthenticated ? <TradingAnnualBudgetDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualBudgetLineChange/Index" element={isAuthenticated ? <TradingAnnualBudgetLineChangePage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualBudgetLineChange/New" element={isAuthenticated ? <TradingAnnualBudgetLineChangeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/AnnualBudgetLineChange/:id" element={isAuthenticated ? <TradingAnnualBudgetLineChangeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterClient/Index" element={isAuthenticated ? <MasterMasterClientPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterClient/New" element={isAuthenticated ? <MasterMasterClientNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterClient/:id" element={isAuthenticated ? <MasterMasterClientDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Sector/Index" element={isAuthenticated ? <MasterSectorPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Sector/New" element={isAuthenticated ? <MasterSectorNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Sector/:id" element={isAuthenticated ? <MasterSectorDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMediaType/Index" element={isAuthenticated ? <MasterMasterMediaTypePage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMediaType/New" element={isAuthenticated ? <MasterMasterMediaTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MasterMediaType/:id" element={isAuthenticated ? <MasterMasterMediaTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaGroup/Index" element={isAuthenticated ? <MasterMediaGroupPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaGroup/New" element={isAuthenticated ? <MasterMediaGroupNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/MediaGroup/:id" element={isAuthenticated ? <MasterMediaGroupDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Audience/Index" element={isAuthenticated ? <MasterAudiencePage /> : <Navigate to="/" />} />
	  <Route path="/Master/Audience/New" element={isAuthenticated ? <MasterAudienceNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Audience/:id" element={isAuthenticated ? <MasterAudienceDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/DistributionType/Index" element={isAuthenticated ? <MasterDistributionTypePage /> : <Navigate to="/" />} />
	  <Route path="/Master/DistributionType/New" element={isAuthenticated ? <MasterDistributionTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/DistributionType/:id" element={isAuthenticated ? <MasterDistributionTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/ProgramType/Index" element={isAuthenticated ? <MasterProgramTypePage /> : <Navigate to="/" />} />
	  <Route path="/Master/ProgramType/New" element={isAuthenticated ? <MasterProgramTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/ProgramType/:id" element={isAuthenticated ? <MasterProgramTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/InventoryType/Index" element={isAuthenticated ? <MasterInventoryTypePage /> : <Navigate to="/" />} />
	  <Route path="/Master/InventoryType/New" element={isAuthenticated ? <MasterInventoryTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/InventoryType/:id" element={isAuthenticated ? <MasterInventoryTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/EventType/Index" element={isAuthenticated ? <MasterEventTypePage /> : <Navigate to="/" />} />
	  <Route path="/Master/EventType/New" element={isAuthenticated ? <MasterEventTypeNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/EventType/:id" element={isAuthenticated ? <MasterEventTypeDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/AdDuration/Index" element={isAuthenticated ? <MasterAdDurationPage /> : <Navigate to="/" />} />
	  <Route path="/Master/AdDuration/New" element={isAuthenticated ? <MasterAdDurationNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/AdDuration/:id" element={isAuthenticated ? <MasterAdDurationDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Market/Index" element={isAuthenticated ? <MasterMarketPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Market/New" element={isAuthenticated ? <MasterMarketNewPage /> : <Navigate to="/" />} />
	  <Route path="/Master/Market/:id" element={isAuthenticated ? <MasterMarketDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCommitment/Index" element={isAuthenticated ? <TradingDealCommitmentPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCommitment/New" element={isAuthenticated ? <TradingDealCommitmentNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DealCommitment/:id" element={isAuthenticated ? <TradingDealCommitmentDetailPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DirectResponseMapping/Index" element={isAuthenticated ? <TradingDirectResponseMappingPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DirectResponseMapping/New" element={isAuthenticated ? <TradingDirectResponseMappingNewPage /> : <Navigate to="/" />} />
	  <Route path="/Trading/DirectResponseMapping/:id" element={isAuthenticated ? <TradingDirectResponseMappingDetailPage /> : <Navigate to="/" />} />
  </>
);
