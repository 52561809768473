import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails  } from '../../../../services/ApiServices';

export function TradingAnnualBudgetLineChangeNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingAnnualBudgetLineChange>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'AnnualBudgetLineChange');
  const updatedState: ITradingAnnualBudgetLineChange = { Id: "" , LoadingInfo: {}  };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
            updatedState.Status = 3;
      setState(updatedState);
      appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.AnnualBudgetLineChange.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/AnnualBudgetLineChange/View.js"], "initNewTradingAnnualBudgetLineChange", { viewmodel : updatedState, canEdit: true, canCreate: true, canRemove: true, });
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
          <div id="AnnualBudgetLineChange" className="controls-container default block">
              <h3>Create a New <b>Annual Budget Line Change</b></h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="AnnualBudgetLineChangeCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="AnnualBudgetLineChangeLabelBlockedCode">Blocked</label>
				<input id="AnnualBudgetLineChangeCheckBlockedCode" defaultChecked name="AnnualBudgetLineChangeCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AnnualBudgetLineId">Annual  Budget  Line</label><select className="" id="AnnualBudgetLineChangeAnnualBudgetLineId" name="AnnualBudgetLineId" defaultValue={state.AnnualBudgetLineId}   required data-required-msg="Annual  Budget  Line is required"  >{state.AnnualBudgetLineId && <option value={state.AnnualBudgetLineId}>{state.AnnualBudgetLineDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Status" className="required">Status</label>
      <select className="" id="AnnualBudgetLineChangeStatus" name="Status" value={state.Status} required data-required-msg="Status is required" >
        <option value="0">Pending</option>
        <option value="1">Approved</option>
        <option value="2">Rejected</option>
      </select>
    </div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="AnnualBudgetLineChangeSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
              </form>
              <section className="button-toolbar" id="ToolbarAnnualBudgetLineChange">
                <div className="left">
                  <button id="cancelAnnualBudgetLineChangeButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveAnnualBudgetLineChangeButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingAnnualBudgetLineChange {
  LoadingInfo: {
    CreationDate?: string,
    CreatedBy?: string,
    CreatedByDisplayValue?: string,
    UpdateDate?: string,
    UpdatedBy?: string,
    UpdatedByDisplayValue?: string,
    SourceStatus?: number,
    Source?: number
  },
  AnnualBudgetLineId?: string,
  AnnualBudgetLineDisplayValue?: string,
  Status?: number,
  Code?: string,
  Display?: string,
  EntityStatus?: number,
  SourceReference?: string,
  Id: string,
  InactiveFrom?: Date
}

	
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum AnnualBudgetChangeStatusEnum { 'Pending' = 0, 'Approved' = 1, 'Rejected' = 2 }

export function TradingAnnualBudgetLineChangeDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingAnnualBudgetLineChange>({ Id: "" , LoadingInfo: {}  });
  const currentSecurity = useSecurity('Trading', 'AnnualBudgetLineChange');
  React.useEffect(() => {
    if (state.Id == "" && currentSecurity.checkAccess()) {
      getEntityDetails("Trading", "AnnualBudgetLineChange", id).then(result => {
        setState(result.Value[0] as ITradingAnnualBudgetLineChange);
        appendScriptsCallback(["Areas/Trading/Scripts/Resources/en/res.AnnualBudgetLineChange.en.js",
        "Areas/Trading/Scripts/Resources/en/Types.en.js",
        "Areas/Trading/Scripts/Enums.js",
        "Areas/Trading/Scripts/AnnualBudgetLineChange/View.js"
        ,"Areas/Trading/Scripts/Resources/en/res.AnnualBudgetDataChange.en.js"
        ,"Areas/Trading/Scripts/AnnualBudgetDataChange/View.js"
        ], "initDetailTradingAnnualBudgetLineChange", { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id});
      });
    }
  }, []);
  window['reloadTradingAnnualBudgetLineChangeState'] = (data) => {
    setState(data.Value[0] as ITradingAnnualBudgetLineChange);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="AnnualBudgetLineChange" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                    <div className="k-content">
                        <h3>Annual Budget Line Change: <span>{state.Display}</span><span id="AnnualBudgetLineChangeToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }} ></span></h3>
                    </div>
                </div>
                <div className='content-line'>
                  {state.AnnualBudgetLineId && <span className='element'><label>Annual  Budget  Line: <label className="strong">{state.AnnualBudgetLineDisplayValue}</label></label></span>}
                  <span className='element'><label>Status: <label className="strong">{state.Status != undefined ? AnnualBudgetChangeStatusEnum[state.Status] : ''}</label></label></span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none'}} className="content-view row">
		<div className="col-md-2" ><label>Code</label><input id="AnnualBudgetLineChangeCode" max="20" name="Code" type="text" defaultValue={state.Code} className="shortcontrol disabled k-input k-input-solid k-textbox" readOnly disabled  placeholder="AUTO-CODE" /></div>
			<div className="col-md-2">
				<label id="AnnualBudgetLineChangeLabelBlockedCode">Blocked</label>
				<input id="AnnualBudgetLineChangeCheckBlockedCode" defaultChecked name="AnnualBudgetLineChangeCheckBlockedCode" type="checkbox" />
			</div>
			<div className="clear"></div>
		<div className="col-md-4 combobox  " ><label htmlFor="AnnualBudgetLineId">Annual  Budget  Line</label><select className="" id="AnnualBudgetLineChangeAnnualBudgetLineId" name="AnnualBudgetLineId" defaultValue={state.AnnualBudgetLineId}   required data-required-msg="Annual  Budget  Line is required"  >{state.AnnualBudgetLineId && <option value={state.AnnualBudgetLineId}>{state.AnnualBudgetLineDisplayValue}</option>}</select></div>
		<div className="col-md-2 dropdownlist  " >
      <label htmlFor="Status" className="required">Status</label>
      <select className="" id="AnnualBudgetLineChangeStatus" name="Status" value={state.Status} required data-required-msg="Status is required" >
        <option value="0">Pending</option>
        <option value="1">Approved</option>
        <option value="2">Rejected</option>
      </select>
    </div>
		<div className="col-md-4" ><label htmlFor="SourceReference">Source Reference</label><input id="AnnualBudgetLineChangeSourceReference" max="60" name="SourceReference" type="text" defaultValue={state.SourceReference} className="shortcontrol k-input k-input-solid k-textbox"  placeholder="SOURCE-REFERENCE" /></div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4" ><label htmlFor="LoadingInfo_SourceStatus">Loading Status</label><select className="" id="AnnualBudgetLineChangeSourceStatus" name="LoadingInfo.SourceStatus" value={state.LoadingInfo.SourceStatus} ><option value="0">Loaded</option><option value="1">Processed</option><option value="2">Pending</option><option value="3">Reloaded</option><option value="4">Error</option></select></div><div className="col-md-4" ><label htmlFor="LoadingInfo_Source">Source</label><select className="" id="AnnualBudgetLineChangeSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source} ><option value="0">Owned</option><option value="1">ExternalPlanning</option><option value="2">ExternalBuying</option><option value="3">ExternalFinance</option><option value="4">ExternalHub</option></select></div><div className="clear"></div>
                <div className="trackinfo">
                    <div className="line">Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span> </div>
                    <div className="line">Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span> </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none'}} id="ToolbarAnnualBudgetLineChange">
                <div className="left">
                  <button id="cancelAnnualBudgetLineChangeButton" type="button" className="k-button button" title="Cancel">Cancel</button>
                </div>
		            <div className="right">
                  <button id="saveAnnualBudgetLineChangeButton" type="button" className="k-button button" title="Save">Save</button>
                </div>
              </section>
            </div>
          </section>
            <div id="associationAnnualBudgetLineChangeAnnualBudgetDataChange">
              <div className="k-block grid-details">
                <h2>Quarterly Budget Data Change</h2>
                <div id="AnnualBudgetDataChangeGrid" className="grid-control"></div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}


