import React from 'react';
import { Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AnnualBudgetPage } from '../components/pages/custom/AnnualBudget';
import { DDLBudgetPage } from '../components/pages/custom/DDLBudget';
import { OptionsCreditsPage } from '../components/pages/custom/OptionsCredits';
import { OptionsCreditsOldPage } from '../components/pages/custom/OptionsCreditsOld';
import { DirectResponsePage } from '../components/pages/custom/DirectResponse';
import { CancelBudgetPage } from '../components/pages/custom/CancelBudget';
import { OptionsTrackerPage } from '../components/pages/custom/OptionsTracker';
import { ViewerPage } from '../components/pages/custom/Viewer';
import { DirectResponseUploadPage } from '../components/pages/custom/DirectResponseUpload';
import {
  TradingDirectResponseMappingCustomNewPage,
  TradingDirectResponseMappingCustomDetailPage,
} from '../components/pages/Trading/DirectResponseMapping/ViewPage.custom';
import { TradingAgencyProfileCustomPage } from '../components/pages/Trading/AgencyProfile/IndexPage.custom';
import {
  TradingAgencyProfileCustomNewBudgetPage,
  TradingAgencyProfileCustomNewRevenuePage,
  TradingAgencyProfileCustomNewDealPage,
  TradingAgencyProfileDetailCustomPage,
} from '../components/pages/Trading/AgencyProfile/ViewPage.custom';
import { TradingDealCommitmentCustomPage } from '../components/pages/Trading/DealCommitment/IndexPage.custom';
import { MasterClientCustomPage } from '../components/pages/Master/Client/IndexPage.custom';
import { MasterDayPartCustomPage } from '../components/pages/Master/DayPart/IndexPage.custom';
import { MasterDayPartCustomDetailPage } from '../components/pages/Master/DayPart/ViewPage.custom';
import { IndexRevenuePeriodPage } from '../components/pages/custom/IndexRevenuePeriod';
import {
  TradingDealCommitmentCustomDetailPage,
  TradingDealCommitmentCustomNewPage,
} from '../components/pages/Trading/DealCommitment/ViewPage.custom';
import { TradingClientProfileCustomPage } from '../components/pages/Trading/ClientProfile/IndexPage.custom';
import { TradingClientProfileDetailCustomPage } from '../components/pages/Trading/ClientProfile/ViewPage.custom';
import { TradingMediaNetworkProfileCustomPage } from '../components/pages/Trading/MediaNetworkProfile/IndexPage.custom';
import {
  TradingMediaNetworkProfileDetailCustomPage,
  TradingMediaNetworkProfileNewCustomPage,
} from '../components/pages/Trading/MediaNetworkProfile/ViewPage.custom';
import { MasterMediaNetworkDetailCustomPage } from '../components/pages/Master/MediaNetwork/ViewPage.custom';
import { TradingAnnualBudgetLineChangeDetailCustomPage, TradingAnnualBudgetLineChangeApproveCustomPage } from '../components/pages/Trading/AnnualBudgetLineChange/ViewPage.custom';
import { TradingAnnualBudgetLineChangeApprovePage } from '../components/pages/Trading/AnnualBudgetLineChange/IndexPage.custom';
export const createRoutesCustom = (isAuthenticated) => (
  <>
    <Route
      path="/Trading/AnnualBudget/AnnualBudget"
      element={isAuthenticated ? <AnnualBudgetPage /> : <Navigate to="/" />}
    />
    <Route path="/Trading/AnnualBudget/DDLBudget" element={isAuthenticated ? <DDLBudgetPage /> : <Navigate to="/" />} />
    <Route
      path="/Trading/AnnualBudget/OptionsCredits"
      element={isAuthenticated ? <OptionsCreditsPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AnnualBudget/OptionsCreditsOld"
      element={isAuthenticated ? <OptionsCreditsOldPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AnnualBudget/DirectResponse"
      element={isAuthenticated ? <DirectResponsePage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AnnualBudget/CancelBudget"
      element={isAuthenticated ? <CancelBudgetPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AnnualBudget/OptionsTracker"
      element={isAuthenticated ? <OptionsTrackerPage /> : <Navigate to="/" />}
    />
    <Route path="/Trading/AnnualBudget/Viewer" element={isAuthenticated ? <ViewerPage /> : <Navigate to="/" />} />
    <Route
      path="/Trading/AnnualBudget/DirectResponseUpload"
      element={isAuthenticated ? <DirectResponseUploadPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/IndexBudgetPeriod"
      element={isAuthenticated ? <IndexRevenuePeriodPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/DirectResponseMapping/New"
      element={isAuthenticated ? <TradingDirectResponseMappingCustomNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/DirectResponseMapping/:id"
      element={isAuthenticated ? <TradingDirectResponseMappingCustomDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/Index"
      element={isAuthenticated ? <TradingAgencyProfileCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/DealCommitment/Index"
      element={isAuthenticated ? <TradingDealCommitmentCustomPage /> : <Navigate to="/" />}
    />
    <Route path="/Master/Client/Index" element={isAuthenticated ? <MasterClientCustomPage /> : <Navigate to="/" />} />
    <Route path="/Master/DayPart/Index" element={isAuthenticated ? <MasterDayPartCustomPage /> : <Navigate to="/" />} />
    <Route
      path="/Master/DayPart/:id"
      element={isAuthenticated ? <MasterDayPartCustomDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/DealCommitment/New"
      element={isAuthenticated ? <TradingDealCommitmentCustomNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/DealCommitment/:id"
      element={isAuthenticated ? <TradingDealCommitmentCustomDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/NewRevenue"
      element={isAuthenticated ? <TradingAgencyProfileCustomNewRevenuePage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/NewDeal"
      element={isAuthenticated ? <TradingAgencyProfileCustomNewDealPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/NewBudget"
      element={isAuthenticated ? <TradingAgencyProfileCustomNewBudgetPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/:id"
      element={isAuthenticated ? <TradingAgencyProfileDetailCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ClientProfile/Index"
      element={isAuthenticated ? <TradingClientProfileCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ClientProfile/:id"
      element={isAuthenticated ? <TradingClientProfileDetailCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MediaNetwork/:id"
      element={isAuthenticated ? <MasterMediaNetworkDetailCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/MediaNetworkProfile/Index"
      element={isAuthenticated ? <TradingMediaNetworkProfileCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/MediaNetworkProfile/:id"
      element={isAuthenticated ? <TradingMediaNetworkProfileDetailCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/MediaNetworkProfile/New"
      element={isAuthenticated ? <TradingMediaNetworkProfileNewCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AnnualBudgetLineChange/:id"
      element={isAuthenticated ? <TradingAnnualBudgetLineChangeDetailCustomPage /> : <Navigate to="/" />}
    /><Route
      path="/Trading/AnnualBudgetLineChangeApprove/:id"
      element={isAuthenticated ? <TradingAnnualBudgetLineChangeApproveCustomPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AnnualBudgetLineChangeApprove/Index"
      element={isAuthenticated ? <TradingAnnualBudgetLineChangeApprovePage /> : <Navigate to="/" />}
    />
  </>
);
