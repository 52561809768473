import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingDirectResponseMappingNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingDirectResponseMapping>({ Id: '' });
  const currentSecurity = useSecurity('Trading', 'DirectResponseMapping');
  const updatedState: ITradingDirectResponseMapping = { Id: '' };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.DirectResponseMapping.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/DirectResponseMapping/View.js',
        ],
        'initNewTradingDirectResponseMapping',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DirectResponseMapping" className="controls-container default block">
              <h3>
                Create a New <b>Direct Response Mappings</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DirectResponseMappingCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DirectResponseMappingLabelBlockedCode">Blocked</label>
                  <input
                    id="DirectResponseMappingCheckBlockedCode"
                    defaultChecked
                    name="DirectResponseMappingCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="BoundedContext">Bounded Context</label>
                  <input
                    required
                    data-required-msg="Bounded Context is required"
                    id="DirectResponseMappingBoundedContext"
                    name="BoundedContext"
                    type="text"
                    defaultValue={state.BoundedContext}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="DirectResponseMappingAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="Entity">Entity</label>
                  <input
                    required
                    data-required-msg="Entity is required"
                    id="DirectResponseMappingEntity"
                    name="Entity"
                    type="text"
                    defaultValue={state.Entity}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EntityName">Entity Name</label>
                  <input
                    required
                    data-required-msg="Entity Name is required"
                    id="DirectResponseMappingEntityName"
                    name="EntityName"
                    type="text"
                    defaultValue={state.EntityName}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DirectResponseMappingSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarDirectResponseMapping">
                <div className="left">
                  <button
                    id="cancelDirectResponseMappingButton"
                    type="button"
                    className="k-button button"
                    title="Cancel"
                  >
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDirectResponseMappingButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingDirectResponseMapping {
  BoundedContext?: string;
  Alias?: string;
  Entity?: string;
  EntityName?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function TradingDirectResponseMappingDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingDirectResponseMapping>({ Id: '' });
  const currentSecurity = useSecurity('Trading', 'DirectResponseMapping');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'DirectResponseMapping', id).then((result) => {
        setState(result.Value[0] as ITradingDirectResponseMapping);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.DirectResponseMapping.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/DirectResponseMapping/View.js',
          ],
          'initDetailTradingDirectResponseMapping',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingDirectResponseMappingState'] = (data) => {
    setState(data.Value[0] as ITradingDirectResponseMapping);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DirectResponseMapping" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Direct Response Mappings: <span>{state.Display}</span>
                      <span
                        id="DirectResponseMappingToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line"></div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DirectResponseMappingCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DirectResponseMappingLabelBlockedCode">Blocked</label>
                  <input
                    id="DirectResponseMappingCheckBlockedCode"
                    defaultChecked
                    name="DirectResponseMappingCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="BoundedContext">Bounded Context</label>
                  <input
                    required
                    data-required-msg="Bounded Context is required"
                    id="DirectResponseMappingBoundedContext"
                    name="BoundedContext"
                    type="text"
                    defaultValue={state.BoundedContext}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="DirectResponseMappingAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="Entity">Entity</label>
                  <input
                    required
                    data-required-msg="Entity is required"
                    id="DirectResponseMappingEntity"
                    name="Entity"
                    type="text"
                    defaultValue={state.Entity}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EntityName">Entity Name</label>
                  <input
                    required
                    data-required-msg="Entity Name is required"
                    id="DirectResponseMappingEntityName"
                    name="EntityName"
                    type="text"
                    defaultValue={state.EntityName}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DirectResponseMappingSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarDirectResponseMapping">
                <div className="left">
                  <button
                    id="cancelDirectResponseMappingButton"
                    type="button"
                    className="k-button button"
                    title="Cancel"
                  >
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDirectResponseMappingButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
