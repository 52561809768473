import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingAgencyProfileCustomNewBudgetPage(): React.ReactElement {
  const [state, setState] = useState<ITradingAgencyProfile>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'AgencyProfile');
  const updatedState: ITradingAgencyProfile = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.CurrentForecastYear = new Date().getFullYear().toString();
      updatedState.Type = 4;
      updatedState.BreakCriteria = 2;
      updatedState.BreakCriteriaClosing = 0;
      updatedState.ShowStatus = 1;
      updatedState.DisableForecast = 1;
      updatedState.DisableCommission = 1;
      updatedState.InputCriteria = 0;
      updatedState.InputLocked = 1;
      updatedState.EnableWorkflow = 1;
      updatedState.CustomClosing = 1;
      updatedState.EnableSnapshot = 1;
      updatedState.DefaultSpendType = 2;
      updatedState.NetGrossRatio = 85;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/AgencyProfile/View.custom.js',
        ],
        'initNewTradingAgencyProfile',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="AgencyProfile" className="controls-container default block">
              <h3>
                Create a New <b>Agency Profiles</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="AgencyProfileCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="AgencyProfileLabelBlockedCode">Blocked</label>
                  <input
                    id="AgencyProfileCheckBlockedCode"
                    defaultChecked
                    name="AgencyProfileCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="AgencyProfileType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                    disabled
                  >
                    <option value="4">Budget</option>
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AgencyId">Agency</label>
                  <select
                    className=""
                    id="AgencyProfileAgencyId"
                    name="AgencyId"
                    defaultValue={state.AgencyId}
                    required
                    data-required-msg="Agency is required"
                  >
                    {state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="NetGrossRatio">Net/Gross Ratio</label>
                  <input
                    required
                    data-required-msg="Net/Gross Ratio is required"
                    data-val-number="The field Net/Gross Ratio must be a number."
                    id="AgencyProfileNetGrossRatio"
                    name="NetGrossRatio"
                    type="text"
                    defaultValue={state.NetGrossRatio}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="content-header">Closing & Calendar Periods</div>
                <div className="col-md-2">
                  <label htmlFor="CurrentForecastYear">Current Forecast Year</label>
                  <input
                    required
                    data-required-msg="Current Forecast Year is required"
                    data-val-number="The field Current Forecast Year must be a number."
                    id="AgencyProfileCurrentForecastYear"
                    name="CurrentForecastYear"
                    type="text"
                    defaultValue={state.CurrentForecastYear}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label id="AgencyProfileLabelCreatePeriods">Force Create Periods</label>
                  <input
                    id="AgencyProfileCreatePeriods"
                    defaultChecked
                    name="CreatePeriods"
                    type="checkbox"
                    value={state.CreatePeriods}
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="BreakCriteria" className="required">
                    Break Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileBreakCriteria"
                    name="BreakCriteria"
                    value={state.BreakCriteria}
                    required
                    data-required-msg="Break Criteria is required"
                  >
                    <option value="5">Week</option>
                    <option value="3">Month</option>
                    <option value="2">Quarter</option>
                    <option value="1">Half Annual</option>
                    <option value="0">Annual</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="BreakCriteriaClosing" className="required">
                    Closing Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileBreakCriteriaClosing"
                    name="BreakCriteriaClosing"
                    value={state.BreakCriteriaClosing}
                    required
                    data-required-msg="Closing Criteria is required"
                  >
                    <option value="5">Week</option>
                    <option value="3">Month</option>
                    <option value="2">Quarter</option>
                    <option value="1">Half Annual</option>
                    <option value="0">Annual</option>
                  </select>
                </div>
                <div className="content-header">Forecast Behaviours</div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="DisableForecast" className="required">
                    Disable Forecast
                  </label>
                  <select
                    className=""
                    id="AgencyProfileDisableForecast"
                    name="DisableForecast"
                    value={state.DisableForecast}
                    required
                    data-required-msg="Disable Forecast is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="CustomClosing" className="required">
                    Custom Closing
                  </label>
                  <select
                    className=""
                    id="AgencyProfileCustomClosing"
                    name="CustomClosing"
                    value={state.CustomClosing}
                    required
                    data-required-msg="Custom Closing is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="EnableSnapshot" className="required">
                    Enable Snapshot
                  </label>
                  <select
                    className=""
                    id="AgencyProfileEnableSnapshot"
                    name="EnableSnapshot"
                    value={state.EnableSnapshot}
                    required
                    data-required-msg="Enable Snapshot is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="EnableWorkflow" className="required">
                    Workflow
                  </label>
                  <select
                    className=""
                    id="AgencyProfileEnableWorkflow"
                    name="EnableWorkflow"
                    value={state.EnableWorkflow}
                    required
                    data-required-msg="Workflow is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="DefaultSpendType" className="required">
                    Input Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileDefaultSpendType"
                    name="DefaultSpendType"
                    value={state.DefaultSpendType}
                    required
                    data-required-msg="Input Criteria is required"
                  >
                    <option value="2">Ordered</option>
                    <option value="3">Billed</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Comments">Comments</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="AgencyProfileComments"
                      name="Comments"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Comments}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="AgencyProfileSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarAgencyProfile">
                <div className="left">
                  <button id="cancelAgencyProfileButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveAgencyProfileButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

export function TradingAgencyProfileCustomNewRevenuePage(): React.ReactElement {
  const [state, setState] = useState<ITradingAgencyProfile>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'AgencyProfile');
  const updatedState: ITradingAgencyProfile = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.CurrentForecastYear = new Date().getFullYear().toString();
      updatedState.Type = 0;
      updatedState.BreakCriteria = 2;
      updatedState.BreakCriteriaClosing = 0;
      updatedState.ShowStatus = 1;
      updatedState.DisableForecast = 1;
      updatedState.DisableCommission = 1;
      updatedState.InputCriteria = 0;
      updatedState.InputLocked = 1;
      updatedState.EnableWorkflow = 1;
      updatedState.CustomClosing = 1;
      updatedState.EnableSnapshot = 1;
      updatedState.DefaultSpendType = 2;
      updatedState.NetGrossRatio = 85;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/AgencyProfile/View.custom.js',
        ],
        'initNewTradingAgencyProfile',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="AgencyProfile" className="controls-container default block">
              <h3>
                Create a New <b>Agency Profiles</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="AgencyProfileCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="AgencyProfileLabelBlockedCode">Blocked</label>
                  <input
                    id="AgencyProfileCheckBlockedCode"
                    defaultChecked
                    name="AgencyProfileCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="AgencyProfileType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                    disabled
                  >
                    <option value="4">Budget</option>
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AgencyId">Agency</label>
                  <select
                    className=""
                    id="AgencyProfileAgencyId"
                    name="AgencyId"
                    defaultValue={state.AgencyId}
                    required
                    data-required-msg="Agency is required"
                  >
                    {state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="NetGrossRatio">Net/Gross Ratio</label>
                  <input
                    required
                    data-required-msg="Net/Gross Ratio is required"
                    data-val-number="The field Net/Gross Ratio must be a number."
                    id="AgencyProfileNetGrossRatio"
                    name="NetGrossRatio"
                    type="text"
                    defaultValue={state.NetGrossRatio}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="content-header">Closing & Calendar Periods</div>
                <div className="col-md-2">
                  <label htmlFor="CurrentForecastYear">Current Forecast Year</label>
                  <input
                    required
                    data-required-msg="Current Forecast Year is required"
                    data-val-number="The field Current Forecast Year must be a number."
                    id="AgencyProfileCurrentForecastYear"
                    name="CurrentForecastYear"
                    type="text"
                    defaultValue={state.CurrentForecastYear}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label id="AgencyProfileLabelCreatePeriods">Force Create Periods</label>
                  <input
                    id="AgencyProfileCreatePeriods"
                    defaultChecked
                    name="CreatePeriods"
                    type="checkbox"
                    value={state.CreatePeriods}
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="BreakCriteria" className="required">
                    Break Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileBreakCriteria"
                    name="BreakCriteria"
                    value={state.BreakCriteria}
                    required
                    data-required-msg="Break Criteria is required"
                  >
                    <option value="5">Week</option>
                    <option value="3">Month</option>
                    <option value="2">Quarter</option>
                    <option value="1">Half Annual</option>
                    <option value="0">Annual</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="BreakCriteriaClosing" className="required">
                    Closing Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileBreakCriteriaClosing"
                    name="BreakCriteriaClosing"
                    value={state.BreakCriteriaClosing}
                    required
                    data-required-msg="Closing Criteria is required"
                  >
                    <option value="5">Week</option>
                    <option value="3">Month</option>
                    <option value="2">Quarter</option>
                    <option value="1">Half Annual</option>
                    <option value="0">Annual</option>
                  </select>
                </div>
                <div className="content-header">Forecast Behaviours</div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="DisableForecast" className="required">
                    Disable Forecast
                  </label>
                  <select
                    className=""
                    id="AgencyProfileDisableForecast"
                    name="DisableForecast"
                    value={state.DisableForecast}
                    required
                    data-required-msg="Disable Forecast is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="CustomClosing" className="required">
                    Custom Closing
                  </label>
                  <select
                    className=""
                    id="AgencyProfileCustomClosing"
                    name="CustomClosing"
                    value={state.CustomClosing}
                    required
                    data-required-msg="Custom Closing is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="EnableSnapshot" className="required">
                    Enable Snapshot
                  </label>
                  <select
                    className=""
                    id="AgencyProfileEnableSnapshot"
                    name="EnableSnapshot"
                    value={state.EnableSnapshot}
                    required
                    data-required-msg="Enable Snapshot is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="EnableWorkflow" className="required">
                    Workflow
                  </label>
                  <select
                    className=""
                    id="AgencyProfileEnableWorkflow"
                    name="EnableWorkflow"
                    value={state.EnableWorkflow}
                    required
                    data-required-msg="Workflow is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="DefaultSpendType" className="required">
                    Input Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileDefaultSpendType"
                    name="DefaultSpendType"
                    value={state.DefaultSpendType}
                    required
                    data-required-msg="Input Criteria is required"
                  >
                    <option value="2">Ordered</option>
                    <option value="3">Billed</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Comments">Comments</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="AgencyProfileComments"
                      name="Comments"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Comments}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="AgencyProfileSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarAgencyProfile">
                <div className="left">
                  <button id="cancelAgencyProfileButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveAgencyProfileButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

export function TradingAgencyProfileCustomNewDealPage(): React.ReactElement {
  const [state, setState] = useState<ITradingAgencyProfile>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'AgencyProfile');
  const updatedState: ITradingAgencyProfile = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.CurrentForecastYear = new Date().getFullYear().toString();
      updatedState.Type = 0;
      updatedState.BreakCriteria = 2;
      updatedState.BreakCriteriaClosing = 0;
      updatedState.ShowStatus = 1;
      updatedState.DisableForecast = 1;
      updatedState.DisableCommission = 1;
      updatedState.InputCriteria = 0;
      updatedState.InputLocked = 1;
      updatedState.EnableWorkflow = 1;
      updatedState.CustomClosing = 1;
      updatedState.EnableSnapshot = 1;
      updatedState.DefaultSpendType = 2;
      updatedState.NetGrossRatio = 85;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.AgencyProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/AgencyProfile/View.custom.js',
        ],
        'initNewTradingAgencyProfile',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="AgencyProfile" className="controls-container default block">
              <h3>
                Create a New <b>Agency Profiles</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="AgencyProfileCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="AgencyProfileLabelBlockedCode">Blocked</label>
                  <input
                    id="AgencyProfileCheckBlockedCode"
                    defaultChecked
                    name="AgencyProfileCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="AgencyProfileType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                    disabled
                  >
                    <option value="4">Budget</option>
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AgencyId">Agency</label>
                  <select
                    className=""
                    id="AgencyProfileAgencyId"
                    name="AgencyId"
                    defaultValue={state.AgencyId}
                    required
                    data-required-msg="Agency is required"
                  >
                    {state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="NetGrossRatio">Net/Gross Ratio</label>
                  <input
                    required
                    data-required-msg="Net/Gross Ratio is required"
                    data-val-number="The field Net/Gross Ratio must be a number."
                    id="AgencyProfileNetGrossRatio"
                    name="NetGrossRatio"
                    type="text"
                    defaultValue={state.NetGrossRatio}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="content-header">Closing & Calendar Periods</div>
                <div className="col-md-2">
                  <label htmlFor="CurrentForecastYear">Current Forecast Year</label>
                  <input
                    required
                    data-required-msg="Current Forecast Year is required"
                    data-val-number="The field Current Forecast Year must be a number."
                    id="AgencyProfileCurrentForecastYear"
                    name="CurrentForecastYear"
                    type="text"
                    defaultValue={state.CurrentForecastYear}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label id="AgencyProfileLabelCreatePeriods">Force Create Periods</label>
                  <input
                    id="AgencyProfileCreatePeriods"
                    defaultChecked
                    name="CreatePeriods"
                    type="checkbox"
                    value={state.CreatePeriods}
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="BreakCriteria" className="required">
                    Break Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileBreakCriteria"
                    name="BreakCriteria"
                    value={state.BreakCriteria}
                    required
                    data-required-msg="Break Criteria is required"
                  >
                    <option value="5">Week</option>
                    <option value="3">Month</option>
                    <option value="2">Quarter</option>
                    <option value="1">Half Annual</option>
                    <option value="0">Annual</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="BreakCriteriaClosing" className="required">
                    Closing Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileBreakCriteriaClosing"
                    name="BreakCriteriaClosing"
                    value={state.BreakCriteriaClosing}
                    required
                    data-required-msg="Closing Criteria is required"
                  >
                    <option value="5">Week</option>
                    <option value="3">Month</option>
                    <option value="2">Quarter</option>
                    <option value="1">Half Annual</option>
                    <option value="0">Annual</option>
                  </select>
                </div>
                <div className="content-header">Forecast Behaviours</div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="DisableForecast" className="required">
                    Disable Forecast
                  </label>
                  <select
                    className=""
                    id="AgencyProfileDisableForecast"
                    name="DisableForecast"
                    value={state.DisableForecast}
                    required
                    data-required-msg="Disable Forecast is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="CustomClosing" className="required">
                    Custom Closing
                  </label>
                  <select
                    className=""
                    id="AgencyProfileCustomClosing"
                    name="CustomClosing"
                    value={state.CustomClosing}
                    required
                    data-required-msg="Custom Closing is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="EnableSnapshot" className="required">
                    Enable Snapshot
                  </label>
                  <select
                    className=""
                    id="AgencyProfileEnableSnapshot"
                    name="EnableSnapshot"
                    value={state.EnableSnapshot}
                    required
                    data-required-msg="Enable Snapshot is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="EnableWorkflow" className="required">
                    Workflow
                  </label>
                  <select
                    className=""
                    id="AgencyProfileEnableWorkflow"
                    name="EnableWorkflow"
                    value={state.EnableWorkflow}
                    required
                    data-required-msg="Workflow is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="DefaultSpendType" className="required">
                    Input Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileDefaultSpendType"
                    name="DefaultSpendType"
                    value={state.DefaultSpendType}
                    required
                    data-required-msg="Input Criteria is required"
                  >
                    <option value="2">Ordered</option>
                    <option value="3">Billed</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Comments">Comments</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="AgencyProfileComments"
                      name="Comments"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Comments}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="AgencyProfileSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarAgencyProfile">
                <div className="left">
                  <button id="cancelAgencyProfileButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveAgencyProfileButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingAgencyProfile {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  AgencyId?: string;
  AgencyDisplayValue?: string;
  Comments?: string;
  BreakCriteria?: number;
  BreakCriteriaClosing?: number;
  CustomClosing?: number;
  DefaultSpendType?: number;
  DisableCommission?: number;
  DisableForecast?: number;
  EnableSnapshot?: number;
  EnableWorkflow?: number;
  InputCriteria?: number;
  InputLocked?: number;
  ShowStatus?: number;
  Type?: number;
  CurrentForecastYear?: string;
  NetGrossRatio?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
  CreatePeriods?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ApplianceModeEnum {
  'Yes' = 0,
  'No' = 1,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealBreakTypeEnum {
  'Week' = 5,
  'Month' = 3,
  'Quarter' = 2,
  'Half Annual' = 1,
  'Annual' = 0,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DealForecastCategoryEnum {
  'Revenue' = 0,
  'Deal' = 1,
  'Manual' = 2,
  '%Share' = 3,
  'Budget' = 4,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DataLoadCriteriaEnum {
  'MOS' = 0,
  'MOA' = 1,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DataLoadModeEnum {
  'Ordered' = 0,
  'Billed' = 1,
}

export function TradingAgencyProfileDetailCustomPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingAgencyProfile>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'AgencyProfile');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'AgencyProfile', id).then((result) => {
        setState(result.Value[0] as ITradingAgencyProfile);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.AgencyProfile.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/AgencyProfile/View.custom.js',
            'Areas/Trading/Scripts/Resources/en/res.BudgetPeriod.en.js',
            'Areas/Trading/Scripts/BudgetPeriod/View.js',
            'Areas/Trading/Scripts/Resources/en/res.AgencyProfileUserRole.en.js',
            'Areas/Trading/Scripts/AgencyProfileUserRole/View.js',
          ],
          'initDetailTradingAgencyProfile',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingAgencyProfileState'] = (data) => {
    setState(data.Value[0] as ITradingAgencyProfile);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="AgencyProfile" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Agency Profiles: <span>{state.Display}</span>
                      <span
                        id="AgencyProfileToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.AgencyId && (
                    <span className="element">
                      <label>
                        Agency: <label className="strong">{state.AgencyDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  <span className="element">
                    <label>
                      Break Criteria:{' '}
                      <label className="strong">
                        {state.BreakCriteria != undefined ? DealBreakTypeEnum[state.BreakCriteria] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Closing Criteria:{' '}
                      <label className="strong">
                        {state.BreakCriteriaClosing != undefined ? DealBreakTypeEnum[state.BreakCriteriaClosing] : ''}
                      </label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      Custom Closing:{' '}
                      <label className="strong">
                        {state.CustomClosing != undefined ? ApplianceModeEnum[state.CustomClosing] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Disable Commission:{' '}
                      <label className="strong">
                        {state.DisableCommission != undefined ? ApplianceModeEnum[state.DisableCommission] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Disable Forecast:{' '}
                      <label className="strong">
                        {state.DisableForecast != undefined ? ApplianceModeEnum[state.DisableForecast] : ''}
                      </label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      Enable Snapshot:{' '}
                      <label className="strong">
                        {state.EnableSnapshot != undefined ? ApplianceModeEnum[state.EnableSnapshot] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Workflow:{' '}
                      <label className="strong">
                        {state.EnableWorkflow != undefined ? ApplianceModeEnum[state.EnableWorkflow] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Input Locked:{' '}
                      <label className="strong">
                        {state.InputLocked != undefined ? ApplianceModeEnum[state.InputLocked] : ''}
                      </label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      % Status:{' '}
                      <label className="strong">
                        {state.ShowStatus != undefined ? ApplianceModeEnum[state.ShowStatus] : ''}
                      </label>
                    </label>
                  </span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="AgencyProfileCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="AgencyProfileLabelBlockedCode">Blocked</label>
                  <input
                    id="AgencyProfileCheckBlockedCode"
                    defaultChecked
                    name="AgencyProfileCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="AgencyProfileType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                    disabled
                  >
                    <option value="4">Budget</option>
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AgencyId">Agency</label>
                  <select
                    className=""
                    id="AgencyProfileAgencyId"
                    name="AgencyId"
                    defaultValue={state.AgencyId}
                    required
                    data-required-msg="Agency is required"
                  >
                    {state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="NetGrossRatio">Net/Gross Ratio</label>
                  <input
                    required
                    data-required-msg="Net/Gross Ratio is required"
                    data-val-number="The field Net/Gross Ratio must be a number."
                    id="AgencyProfileNetGrossRatio"
                    name="NetGrossRatio"
                    type="text"
                    defaultValue={state.NetGrossRatio}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="content-header">Closing & Calendar Periods</div>
                <div className="col-md-2">
                  <label htmlFor="CurrentForecastYear">Current Forecast Year</label>
                  <input
                    required
                    data-required-msg="Current Forecast Year is required"
                    data-val-number="The field Current Forecast Year must be a number."
                    id="AgencyProfileCurrentForecastYear"
                    name="CurrentForecastYear"
                    type="text"
                    defaultValue={state.CurrentForecastYear}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="BreakCriteria" className="required">
                    Break Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileBreakCriteria"
                    name="BreakCriteria"
                    value={state.BreakCriteria}
                    required
                    data-required-msg="Break Criteria is required"
                  >
                    <option value="5">Week</option>
                    <option value="3">Month</option>
                    <option value="2">Quarter</option>
                    <option value="1">Half Annual</option>
                    <option value="0">Annual</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="BreakCriteriaClosing" className="required">
                    Closing Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileBreakCriteriaClosing"
                    name="BreakCriteriaClosing"
                    value={state.BreakCriteriaClosing}
                    required
                    data-required-msg="Closing Criteria is required"
                  >
                    <option value="5">Week</option>
                    <option value="3">Month</option>
                    <option value="2">Quarter</option>
                    <option value="1">Half Annual</option>
                    <option value="0">Annual</option>
                  </select>
                </div>
                <div className="content-header">Forecast Behaviours</div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="DisableForecast" className="required">
                    Disable Forecast
                  </label>
                  <select
                    className=""
                    id="AgencyProfileDisableForecast"
                    name="DisableForecast"
                    value={state.DisableForecast}
                    required
                    data-required-msg="Disable Forecast is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="CustomClosing" className="required">
                    Custom Closing
                  </label>
                  <select
                    className=""
                    id="AgencyProfileCustomClosing"
                    name="CustomClosing"
                    value={state.CustomClosing}
                    required
                    data-required-msg="Custom Closing is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="EnableSnapshot" className="required">
                    Enable Snapshot
                  </label>
                  <select
                    className=""
                    id="AgencyProfileEnableSnapshot"
                    name="EnableSnapshot"
                    value={state.EnableSnapshot}
                    required
                    data-required-msg="Enable Snapshot is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="EnableWorkflow" className="required">
                    Workflow
                  </label>
                  <select
                    className=""
                    id="AgencyProfileEnableWorkflow"
                    name="EnableWorkflow"
                    value={state.EnableWorkflow}
                    required
                    data-required-msg="Workflow is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="DefaultSpendType" className="required">
                    Input Criteria
                  </label>
                  <select
                    className=""
                    id="AgencyProfileDefaultSpendType"
                    name="DefaultSpendType"
                    value={state.DefaultSpendType}
                    required
                    data-required-msg="Input Criteria is required"
                  >
                    <option value="2">Ordered</option>
                    <option value="3">Billed</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Comments">Comments</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="AgencyProfileComments"
                      name="Comments"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Comments}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="AgencyProfileSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="AgencyProfileSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                    disabled
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="AgencyProfileSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                    disabled
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarAgencyProfile">
                <div className="left">
                  <button id="cancelAgencyProfileButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveAgencyProfileButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="AgencyProfileTabstrip">
            <ul>
              <li id="AgencyProfileBudgetPeriod" className="k-active">
                <i className="fas fa-calendar"></i>
                <span className="labeltext">Budgeting Periods</span>
              </li>
              <li id="AgencyProfileAgencyProfileUserRole">
                <i className="fas fa-users"></i>
                <span className="labeltext">User Roles</span>
              </li>
            </ul>
            <div>
              <div id="associationAgencyProfileBudgetPeriod">
                <div className="k-block grid-details">
                  <h2>Budgeting Periods</h2>
                  <div id="BudgetPeriodGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
            <div>
              <div id="associationAgencyProfileAgencyProfileUserRole">
                <div className="k-block grid-details">
                  <h2>User Roles</h2>
                  <div id="AgencyProfileUserRoleGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
