import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function MasterDistributionTypeNewPage(): React.ReactElement {
  const [state, setState] = useState<IMasterDistributionType>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'DistributionType');
  const updatedState: IMasterDistributionType = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.WithDayPart = 0;
      updatedState.WithGenre = 1;
      updatedState.GrossNet = 1;
      updatedState.WithEquivalizedUnits = 0;
      updatedState.Conversion = 0;
      updatedState.ConversionHisp = 0;
      updatedState.Kind = 0;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Master/Scripts/Resources/en/res.DistributionType.en.js',
          'Areas/Master/Scripts/Resources/en/Types.en.js',
          'Areas/Master/Scripts/Enums.js',
          'Areas/Master/Scripts/DistributionType/View.js',
        ],
        'initNewMasterDistributionType',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DistributionType" className="controls-container default block">
              <h3>
                Create a New <b>Media Type</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DistributionTypeCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DistributionTypeLabelBlockedCode">Blocked</label>
                  <input
                    id="DistributionTypeCheckBlockedCode"
                    defaultChecked
                    name="DistributionTypeCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="DistributionTypeEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() =>
                      window['OnChangeReply']('DistributionTypeEnglishName', 'DistributionTypeNativeName')
                    }
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="DistributionTypeNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="DistributionTypeEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() =>
                      window['OnChangeReply']('DistributionTypeEnglishShortName', 'DistributionTypeNativeShortName')
                    }
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="DistributionTypeNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AudienceId">Default Demo</label>
                  <select
                    className=""
                    id="DistributionTypeAudienceId"
                    name="AudienceId"
                    defaultValue={state.AudienceId}
                  >
                    {state.AudienceId && <option value={state.AudienceId}>{state.AudienceDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterMediaTypeId">Master Media Type</label>
                  <select
                    className=""
                    id="DistributionTypeMasterMediaTypeId"
                    name="MasterMediaTypeId"
                    defaultValue={state.MasterMediaTypeId}
                    required
                    data-required-msg="Master  Media  Type is required"
                  >
                    {state.MasterMediaTypeId && (
                      <option value={state.MasterMediaTypeId}>{state.MasterMediaTypeDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Conversion" className="required">
                    Conversion For
                  </label>
                  <select
                    className=""
                    id="DistributionTypeConversion"
                    name="Conversion"
                    value={state.Conversion}
                    required
                    data-required-msg="Conversion For is required"
                  >
                    <option value="0">Media Network Profile</option>
                    <option value="1">Daypart</option>
                    <option value="2">Media Type</option>
                    <option value="3">Manual</option>
                    <option value="4">None</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="ConversionHisp" className="required">
                    Conversion For Hispanic
                  </label>
                  <select
                    className=""
                    id="DistributionTypeConversionHisp"
                    name="ConversionHisp"
                    value={state.ConversionHisp}
                    required
                    data-required-msg="Conversion For Hispanic is required"
                  >
                    <option value="0">Media Network Profile</option>
                    <option value="1">Daypart</option>
                    <option value="2">Media Type</option>
                    <option value="3">Manual</option>
                    <option value="4">None</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="GrossNet" className="required">
                    Gross/Net
                  </label>
                  <select
                    className=""
                    id="DistributionTypeGrossNet"
                    name="GrossNet"
                    value={state.GrossNet}
                    required
                    data-required-msg="Gross/Net is required"
                  >
                    <option value="0">Net</option>
                    <option value="1">Gross</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Kind" className="required">
                    Media Kind
                  </label>
                  <select
                    className=""
                    id="DistributionTypeKind"
                    name="Kind"
                    value={state.Kind}
                    required
                    data-required-msg="Media Kind is required"
                  >
                    <option value="0">Linear</option>
                    <option value="1">Digital</option>
                    <option value="2">Multi</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="WithDayPart" className="required">
                    DayPart Required
                  </label>
                  <select
                    className=""
                    id="DistributionTypeWithDayPart"
                    name="WithDayPart"
                    value={state.WithDayPart}
                    required
                    data-required-msg="DayPart Required is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="WithEquivalizedUnits" className="required">
                    Equivalized Units
                  </label>
                  <select
                    className=""
                    id="DistributionTypeWithEquivalizedUnits"
                    name="WithEquivalizedUnits"
                    value={state.WithEquivalizedUnits}
                    required
                    data-required-msg="Equivalized Units is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="WithGenre" className="required">
                    Genre Required
                  </label>
                  <select
                    className=""
                    id="DistributionTypeWithGenre"
                    name="WithGenre"
                    value={state.WithGenre}
                    required
                    data-required-msg="Genre Required is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DistributionTypeSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarDistributionType">
                <div className="left">
                  <button id="cancelDistributionTypeButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDistributionTypeButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterDistributionType {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  EnglishName?: string;
  EnglishShortName?: string;
  NativeName?: string;
  NativeShortName?: string;
  AudienceId?: string;
  AudienceDisplayValue?: string;
  MasterMediaTypeId?: string;
  MasterMediaTypeDisplayValue?: string;
  Conversion?: number;
  ConversionHisp?: number;
  GrossNet?: number;
  Kind?: number;
  WithDayPart?: number;
  WithEquivalizedUnits?: number;
  WithGenre?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ApplianceModeEnum {
  'Yes' = 0,
  'No' = 1,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum NetGrossFlagEnum {
  'Net' = 0,
  'Gross' = 1,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ConversionTypeEnum {
  'Media Network Profile' = 0,
  'Daypart' = 1,
  'Media Type' = 2,
  'Manual' = 3,
  'None' = 4,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum MediaTypeKindEnum {
  'Linear' = 0,
  'Digital' = 1,
  'Multi' = 2,
}

export function MasterDistributionTypeDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterDistributionType>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'DistributionType');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Master', 'DistributionType', id).then((result) => {
        setState(result.Value[0] as IMasterDistributionType);
        appendScriptsCallback(
          [
            'Areas/Master/Scripts/Resources/en/res.DistributionType.en.js',
            'Areas/Master/Scripts/Resources/en/Types.en.js',
            'Areas/Master/Scripts/Enums.js',
            'Areas/Master/Scripts/DistributionType/View.js',
          ],
          'initDetailMasterDistributionType',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadMasterDistributionTypeState'] = (data) => {
    setState(data.Value[0] as IMasterDistributionType);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DistributionType" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Media Type: <span>{state.Display}</span>
                      <span
                        id="DistributionTypeToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.AudienceId && (
                    <span className="element">
                      <label>
                        Default Demo: <label className="strong">{state.AudienceDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MasterMediaTypeId && (
                    <span className="element">
                      <label>
                        Master Media Type: <label className="strong">{state.MasterMediaTypeDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  <span className="element">
                    <label>
                      Gross/Net:{' '}
                      <label className="strong">
                        {state.GrossNet != undefined ? NetGrossFlagEnum[state.GrossNet] : ''}
                      </label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      DayPart Required:{' '}
                      <label className="strong">
                        {state.WithDayPart != undefined ? ApplianceModeEnum[state.WithDayPart] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Equivalized Units:{' '}
                      <label className="strong">
                        {state.WithEquivalizedUnits != undefined ? ApplianceModeEnum[state.WithEquivalizedUnits] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Genre Required:{' '}
                      <label className="strong">
                        {state.WithGenre != undefined ? ApplianceModeEnum[state.WithGenre] : ''}
                      </label>
                    </label>
                  </span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DistributionTypeCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DistributionTypeLabelBlockedCode">Blocked</label>
                  <input
                    id="DistributionTypeCheckBlockedCode"
                    defaultChecked
                    name="DistributionTypeCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="DistributionTypeEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() =>
                      window['OnChangeReply']('DistributionTypeEnglishName', 'DistributionTypeNativeName')
                    }
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="DistributionTypeNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="DistributionTypeEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() =>
                      window['OnChangeReply']('DistributionTypeEnglishShortName', 'DistributionTypeNativeShortName')
                    }
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="DistributionTypeNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AudienceId">Default Demo</label>
                  <select
                    className=""
                    id="DistributionTypeAudienceId"
                    name="AudienceId"
                    defaultValue={state.AudienceId}
                  >
                    {state.AudienceId && <option value={state.AudienceId}>{state.AudienceDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterMediaTypeId">Master Media Type</label>
                  <select
                    className=""
                    id="DistributionTypeMasterMediaTypeId"
                    name="MasterMediaTypeId"
                    defaultValue={state.MasterMediaTypeId}
                    required
                    data-required-msg="Master  Media  Type is required"
                  >
                    {state.MasterMediaTypeId && (
                      <option value={state.MasterMediaTypeId}>{state.MasterMediaTypeDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Conversion" className="required">
                    Conversion For
                  </label>
                  <select
                    className=""
                    id="DistributionTypeConversion"
                    name="Conversion"
                    value={state.Conversion}
                    required
                    data-required-msg="Conversion For is required"
                  >
                    <option value="0">Media Network Profile</option>
                    <option value="1">Daypart</option>
                    <option value="2">Media Type</option>
                    <option value="3">Manual</option>
                    <option value="4">None</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="ConversionHisp" className="required">
                    Conversion For Hispanic
                  </label>
                  <select
                    className=""
                    id="DistributionTypeConversionHisp"
                    name="ConversionHisp"
                    value={state.ConversionHisp}
                    required
                    data-required-msg="Conversion For Hispanic is required"
                  >
                    <option value="0">Media Network Profile</option>
                    <option value="1">Daypart</option>
                    <option value="2">Media Type</option>
                    <option value="3">Manual</option>
                    <option value="4">None</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="GrossNet" className="required">
                    Gross/Net
                  </label>
                  <select
                    className=""
                    id="DistributionTypeGrossNet"
                    name="GrossNet"
                    value={state.GrossNet}
                    required
                    data-required-msg="Gross/Net is required"
                  >
                    <option value="0">Net</option>
                    <option value="1">Gross</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Kind" className="required">
                    Media Kind
                  </label>
                  <select
                    className=""
                    id="DistributionTypeKind"
                    name="Kind"
                    value={state.Kind}
                    required
                    data-required-msg="Media Kind is required"
                  >
                    <option value="0">Linear</option>
                    <option value="1">Digital</option>
                    <option value="2">Multi</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="WithDayPart" className="required">
                    DayPart Required
                  </label>
                  <select
                    className=""
                    id="DistributionTypeWithDayPart"
                    name="WithDayPart"
                    value={state.WithDayPart}
                    required
                    data-required-msg="DayPart Required is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="WithEquivalizedUnits" className="required">
                    Equivalized Units
                  </label>
                  <select
                    className=""
                    id="DistributionTypeWithEquivalizedUnits"
                    name="WithEquivalizedUnits"
                    value={state.WithEquivalizedUnits}
                    required
                    data-required-msg="Equivalized Units is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="WithGenre" className="required">
                    Genre Required
                  </label>
                  <select
                    className=""
                    id="DistributionTypeWithGenre"
                    name="WithGenre"
                    value={state.WithGenre}
                    required
                    data-required-msg="Genre Required is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DistributionTypeSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="DistributionTypeSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="DistributionTypeSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarDistributionType">
                <div className="left">
                  <button id="cancelDistributionTypeButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDistributionTypeButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
