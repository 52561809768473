export const CommonResources = {
  LearnMore: 'Learn More',
  ContactMe: 'ContactMe',
  Button_OK: 'Ok, I got it!',
  Button_Save: 'Save',
  Button_Cancel: 'Cancel',
  Button_Close: 'Close',
  Button_ApplyFilters: 'Apply',
  Button_Search: 'Search',
  Button_Back: 'Back',
  Button_Bookmark: 'Manage Bookmarks',
  Button_FullScreen: 'Full-Screen Mode',
  Button_Delete: 'Delete',
  Button_Disable: 'Disable',
  Button_Duplicate: 'Duplicate',
  Button_Activate: 'Activate',
  Button_Edit: 'Edit Details',
  Button_New: 'Add New',
  Button_NewChild: 'Add New Child',
  Button_View: 'Open Details',
  Button_ExportExcel: 'Export to Excel',
  Button_ShowActives: 'Show Actives',
  Button_ShowInactives: 'Show Deletes',
  Button_ShowDisable: 'Show Disables',
  Button_ShowStatus: 'Show Status',
  Button_SyncMetadata: 'Sync Metadata',
  Button_Reset: 'Reset',
  Button_Reload: 'Reload',
  Button_Clean: 'Clean Filters',
  Button_Dismiss: 'Dismiss',
  Button_ShowMore: 'Show More',
  Button_ShowLess: 'Show Less',
  Button_ClearFilters: 'Clear Filters',
  Button_Navigate: 'Navigate',
  Notifications: 'Notifications',
  Tab_Title: 'General Info',
  Tab_Detail: 'Details',
  BreadCrumb_View: 'Detail',
  BreadCrumb_Index: 'Index',
  BreadCrumb_New: 'Create New',
  CreatedOn: 'Created on',
  ModifiedOn: 'Modified on',
  DeletedSince: 'Deleted since',
  CreatedBy: 'Created by ',
  SourceDeletedDate: 'Deleted on AAD since ',
  SourceCreatedDate: 'Created on AAD on ',
  ModifiedBy: 'Modified by ',
  Description: 'Description',
  Description_Hint: 'Hint: Please enter a description or any additional information.',
  Inactive: 'Deleted since',
  Active: 'Active in Origin',
  ActiveAad: 'Active in AAD',
  Name: 'Name',
  Role: 'Role Name',
  Table: 'Table',
  Field: 'Field',
  Hidden: 'Hidden',
  Operator: 'Operator',
  SingleSelection: 'Selection',
  Values: 'Values',
  Email: 'Email',
  Members: 'Members',
  Tables: 'Tables + DAX',
  Upn: 'UPN',
  AccessType: 'Access Type',
  Connector: 'Connector Type',
  Server: 'Server',
  Database: 'Database',
  IsoCode: '',
  Name_Hint: 'Hint: Please enter the name.',
  Name_Placeholder: 'Type a meaningful name',
  Code: 'Code',
  Code_Hint: 'Hint: Please enter a code.',
  Code_Placeholder: 'Type a unique code',
  LongName: 'Long Name',
  LongName_Hint: 'Hint: Please enter a long name.',
  LongName_Placeholder: 'Type a descriptive long name',
  SourceReference: 'Source Reference',
  SourceReference_Hint: 'Hint: It represents the source references if there is any.',
  SourceReference_Placeholder: 'The record reference on the original source',
  KeyReference: 'Import Key Reference',
  KeyReference_Hint: 'Hint: It represents the the key reference from the imported data source if there is any.',
  Form_CreationTitle: 'Create a New ',
  Form_CreationIntro: 'Please fill in the fields, once completed click on Save to proceed with the creation:',
  Dialog_ConfirmTitle: 'Confirm Action',
  Dialog_MessageDisable: 'Are you sure you want to disable the selected ',
  Dialog_MessageDelete: 'Are you sure you want to inactivate/delete the selected ',
  Dialog_MessageDeleteGroup:
    'Deleting AAD group will only remove the group-permission, group-user information defined within Origin, no change will be made to user group in AAD including the member list.',
  Dialog_MessageActivate: 'Are you sure you want to activate the selected ',
  Dialog_PurgeNotifications: 'Are you sure you want to purge all the notifications?',
  Dialog_ConfirmClick: 'Please click on Yes button if you want to continue.',
  ProvidedBy: 'Provided by ',
  Search: 'Search...',
  AllGranted: 'All Granted',
  AllGranted_Keys: 'All Keys Granted',
  AllGranted_Manual: 'Granted Manually',
  AllGranted_NoKeys: 'No Keys',
  AllUnGranted: 'All Ungranted',
  AllGranted_Hint: 'Please check if the full hierarchy should be granted',
  StrictMode: 'Strict',
  StrictMode_Hint: 'Please check if it is required the strictly saving of all the keys along the hierarchy',
  ShowHierarchy: 'Show Hierarchy',
  Search_PlaceHolder: 'Search...',
  SearchGraph_PlaceHolder: 'Search by name, email, upn...',
  SearchAppGraph_PlaceHolder: 'Search by application id...',
  Select_PlaceHolder: 'Select one option...',
  SearchMinWidth_Message: 'Please type at least 3 characters to start the searching',
  SearchAppMinWidth_Message: 'Please type the complete GUID to start the searching',
  Form_ErrorTitle: 'Please you must fill the following fields',
  Form_ErrorDetail: 'Please check the validation summary for more information.',
  Form_ErrorRequired: 'You must fill this field, it is required',
  Form_FillFields: 'Please fill/fix the highlighted fields:',
  Dialog_Create: 'Quick-Create for',
  Dialog_Update: 'Quick-Update for',
  Dialog_Select: 'Select or Create',
  Button_ContactSupport: 'Contact Support',
  Button_Sync: 'Synchronize',
  Button_Print: 'Print Report',
  Button_Login: 'Login',
  Total: 'Total',
  Unknown: 'Unknown',
  TotalSelected: 'Total Selected',
  Error_Title: 'SOMETHING WENT WRONG',
  Success_Title: 'Well done!',
  Loading_Title: 'Loading',
  Configuration: 'Configuration',
  ApplicationSettings: 'Application Settings',
  MyProfile: 'My Profile',
  Profile: 'Profile',
  AccountSettings: 'Account Settings',
  LoggedAs: 'Logged in as:',
  Help: 'Help',
  SignOut: 'Sign Out',
  PurgeNotifications: 'Purge Notifications',
  PoweredBy: 'Powered by',
  PoweredByGroupM: 'Powered by GroupM',
  Filters: 'Filters',
  TotalRecords: 'Total Records Processed ',
  Hello: 'Hello',
  WhatToday: 'What do you want to do today?',
  Info: 'Info',
  Success: 'Success',
  Warning: 'Warning',
  Error: 'Error',
  Progress: 'Progress',
  Yes: 'Yes',
  No: 'No',
  LayoutSettings: 'Layout Settings',
  BookmarkManagement: 'Bookmark Management',
  Message_Unauthorize: 'You are unauthorized',
  Message_UnauthorizeIntro: 'Ups! You are NOT authorized to use this application.',
  Message_ContactAdmin: 'Please contact your system administrator for assistance.',
};

export const Resources = {
  Menu_Filters: 'Filters',
  Menu_Bookmarks: 'Bookmarks',
  Menu_Users: 'User Data Access',
  Menu_RLS: 'Row-Level-Security',
  Menu_Themes: 'Themes',
  Menu_ContactSupport: 'Contact Support',
  RLSEnabled: 'Row-Level-Security Enabled',
  AddBookmark: 'Add Personal Bookmarks',
  DisableBookmark: 'Reset to default',
  ShowBookmark: 'Show Personal Bookmarks',
  ChangeFilters: 'Change active filters',
  ResetFilters: 'Reset Filters to default',
  FitToWidth: 'Fit to Width',
  FitToPage: 'Fit to Page',
  Bookmarks: 'Personal Bookmarks',
  Bookmark_PlaceHolder: 'Type a bookmark name',
  Bookmarks_List: 'Available Bookmarks',
  Bookmarks_Hint: "Capture this report's current state",
  Button_SyncFilters: 'Sync Filters',
  Button_RefreshVisuals:
    'Refresh visuals—when the data model has been updated, refreshing will update all visuals with the latest data.',
  FullScreen: 'Full Screen',
  Administration: 'Administration',
  Help: 'Help',
  ReportCatalog: 'Product Catalog',
  DashboardAdministration_Intro:
    'It seems like you have admin rights. Here you have the shortcuts to the most common administration activities: ',
  Admin: 'Admin',
  Role: 'Role',
  Groups: 'Groups',
  Group: 'Group',
  SecurityManager: 'Security Manager Data',
  EntitiesConfiguration: 'Entities Configuration',
  SecureFields: 'Secure Fields',
  EntityChecker: 'Security Strategy Checker',
  GroupUserPermission: 'Groups, Users and Permissions',
  Permissions: 'Permissions',
  Entity: 'Entity',
  Included: 'Included',
  GroupType: 'Group Type',
  Users: 'Users',
  User: 'User',
  UserType: 'User Type',
  EmbedReportFilter: 'Filter',
  EmbedReportFilters: 'Filters',
  Security: 'Security',
  ReportSetup: 'Preview + Setup',
  SecurityDetail: 'Security Manage Current Configuration',
  EmbedReportPage: 'Page',
  EmbedReportPages: 'Pages',
  EmbedReport: 'Embed Products',
  EmbedReports: 'Embed Products',
  EmbedReports_Description: 'Full management of available embed products',
  Market: 'Market/Region',
  Markets: 'Markets/Regions',
  Markets_Description: 'Full management of available markets/regions',
  AddTags: 'Add Tags',
  ApplicationName: 'CoreM',
  ApplicationContanctTeam:
    'If you have any question or you need further assistance please <b>contact</b> our support team',
  ApplicationIntro: 'This portal provides Trading and Deal management capabilities for your market.</br>',
  ApplicationDescription: 'GIBS',
  Legend_TotalKeys: 'Total Keys',
  Legend_TotalMissing: 'Missing Keys',
  Legend_SelectedKeys: 'Granted Keys',
  Legend_InheritedKeys: 'Inherited Keys',
  Legend_ExcludedKeys: 'Excluded Keys',
  EventTypes: 'Event Types',
  Legend_Granted: 'Granted',
  Legend_KeyValid: 'KeyValid',
  Legend_KeyLocked: 'Key Locked',
  Legend_KeyMissing: 'Missing Key',
  AuditTrail: 'Audit Trail',
  AuditTrails: 'Audit Trails',
  AppName: 'CoreM',
  ApiHelper: 'API Helper',
  CrossTabBuilder: 'Cross-Tab Builder',
  Forms: 'Forms',
  EditableGrids: 'Editable Grids',
  Error: 'Error',
  MessagePanels: 'Message Panels',
  Stepper: 'Step-by-Step Process Template',
  Button_OK: 'Ok, I got it!',
  Button_Save: 'Save',
  Button_Cancel: 'Cancel',
  Button_Close: 'Close',
  Button_Search: 'Search',
  Button_Back: 'Back',
  Button_Previous: 'Previous',
  Button_Next: 'Next',
  Button_Delete: 'Delete',
  Button_Disable: 'Disable',
  Button_Duplicate: 'Duplicate',
  Button_Activate: 'Activate',
  Button_Edit: 'Edit Details',
  Button_EditEntity: 'Quick Edition',
  Button_EditFields: 'Edit Fields',
  Button_CreateSecure: 'Link Security',
  Button_Navigate: 'Navigate',
  Button_New: 'Add New',
  Button_NewChild: 'Add New Child',
  Button_View: 'View Details',
  Button_ExportExcel: 'Export to Excel',
  Button_ShowActives: 'Show Actives',
  Button_ShowInactives: 'Show Deletes',
  Button_ShowDisable: 'Show Disables',
  Button_ShowStatus: 'Show Status',
  Button_Reset: 'Reset',
  Button_Reload: 'Reload',
  Button_Clean: 'Clean Filters',
  Button_Dismiss: 'Dismiss',
  Button_ShowMore: 'Show More',
  Button_ShowLess: 'Show Less',
  Button_ClearFilters: 'Clear Filters',
  Button_CancelChanges: 'Cancel Changes',
  Button_SaveChanges: 'Save Changes',
  Button_ContactSupport: 'Contact Support',
  Button_Sync: 'Synchronize',
  Button_Create: 'Create',
  Button_Apply: 'Apply',
  Button_Remove: 'Remove',
};
