import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function DDLBudgetPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AnnualBudget');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.BudgetPeriod.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AnnualBudget.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AnnualBudgetLine.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          'Areas/Trading/Scripts/AnnualBudget/Index.custom.js',
        ],
        'initSearchAnnualBudgetIndex',
        {
          canEdit: true,
          canCreate: true,
          canRemove: true,
          mode: 'DDLBudget',
          appSettings: currentSecurity.profile.AppSettings.Values,
        },
      );
    }
  }, []);
  return (
    <div className="panelcontent-section" style={{ display: 'none' }}>
      <div
        id="sidebar-section"
        ref={(el) => {
          if (el) {
            el.style.setProperty('padding', '1em 2em', 'important');
          }
        }}
      >
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>Quarterly Budget</h3>
          <div className="field fullwidth-container">
            <label className="required">Broadcast Year</label>
            <div style={{ width: '100%', display: 'flex' }}>
              <input id="inputPreviousYear" style={{ width: '48%', marginRight: '10px' }} />
              <input id="inputYear" style={{ width: '48%', float: 'right' }} />
            </div>
          </div>
          <div className="field fullwidth-container">
            <label className="required">Agency</label>
            <input id="inputAgencyProfile" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Client</label>
            <input id="inputClient" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Budget View</label>
            <input id="inputBudgetMode" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Budget Type</label>
            <input id="inputBudgetType" />
          </div>
          <div className="field fullwidth-container">
            <label>Hispanic</label>
            <input id="inputHispanic" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Media Types</label>
            <input id="inputDistributionType" />
          </div>
          <div className="field fullwidth-container">
            <label>Media Groups</label>
            <input id="inputMediaGroup" />
          </div>
          <div className="field fullwidth-container">
            <label>Media Networks</label>
            <input id="inputMediaNetwork" />
          </div>
          <div className="field fullwidth-container">
            <label>Day Parts</label>
            <input id="inputDayPart" />
          </div>
          <section className="button-toolbar">
            <div className="right">
              <button id="applyFilterButton" type="button" className="k-button k-primary" title="Go">
                Go
              </button>
            </div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars" id="contentBar" style={{ display: 'none' }}></i>
        <div id="AnnualBudgetContainer" className="k-content">
          <div id="AnnualBudgetLineContent">
            <div id="AnnualBudgetLineToolbar"></div>
            <div id="AnnualBudgetLineContainer" className="spreadsheet-container">
              <div id="AnnualBudgetLineSpreadsheet" style={{ width: '100%' }}></div>
            </div>
          </div>
          <div id="AnnualBudgetLineNotify" style={{ width: '100%' }}></div>
        </div>
      </div>
    </div>
  );
}
