import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function MasterDayPartNewPage(): React.ReactElement {
  const [state, setState] = useState<IMasterDayPart>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'DayPart');
  const updatedState: IMasterDayPart = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.Segmentation = 0;
      updatedState.Type = 0;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Master/Scripts/Resources/en/res.DayPart.en.js',
          'Areas/Master/Scripts/Resources/en/Types.en.js',
          'Areas/Master/Scripts/Enums.js',
          'Areas/Master/Scripts/DayPart/View.js',
        ],
        'initNewMasterDayPart',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DayPart" className="controls-container default block">
              <h3>
                Create a New <b>Dayparts</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DayPartCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DayPartLabelBlockedCode">Blocked</label>
                  <input id="DayPartCheckBlockedCode" defaultChecked name="DayPartCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="ConversionAudienceId">Default Demo</label>
                  <select
                    className=""
                    id="DayPartConversionAudienceId"
                    name="ConversionAudienceId"
                    defaultValue={state.ConversionAudienceId}
                  >
                    {state.ConversionAudienceId && (
                      <option value={state.ConversionAudienceId}>{state.ConversionAudienceDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="DayPartAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Segmentation" className="required">
                    Segmentation
                  </label>
                  <select
                    className=""
                    id="DayPartSegmentation"
                    name="Segmentation"
                    value={state.Segmentation}
                    required
                    data-required-msg="Segmentation is required"
                  >
                    <option value="0">All Audiences</option>
                    <option value="1">Kids</option>
                    <option value="2">Adults</option>
                    <option value="3">Sports</option>
                    <option value="4">NA</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="DayPartType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                  >
                    <option value="0">Early Morning</option>
                    <option value="1">Daytime</option>
                    <option value="2">Early Fringe</option>
                    <option value="3">Primetime</option>
                    <option value="4">Late News</option>
                    <option value="5">Late Fringe</option>
                    <option value="6">Overnight</option>
                    <option value="7">Weekend</option>
                    <option value="8">Cable</option>
                    <option value="9">Other</option>
                    <option value="10">NA</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DayPartSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarDayPart">
                <div className="left">
                  <button id="cancelDayPartButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDayPartButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterDayPart {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  ConversionAudienceId?: string;
  ConversionAudienceDisplayValue?: string;
  Alias?: string;
  Segmentation?: number;
  Type?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum SegmentationTypeEnum {
  'All Audiences' = 0,
  'Kids' = 1,
  'Adults' = 2,
  'Sports' = 3,
  'NA' = 4,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DayPartTypeEnum {
  'Early Morning' = 0,
  'Daytime' = 1,
  'Early Fringe' = 2,
  'Primetime' = 3,
  'Late News' = 4,
  'Late Fringe' = 5,
  'Overnight' = 6,
  'Weekend' = 7,
  'Cable' = 8,
  'Other' = 9,
  'NA' = 10,
}

export function MasterDayPartDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterDayPart>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'DayPart');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Master', 'DayPart', id).then((result) => {
        setState(result.Value[0] as IMasterDayPart);
        appendScriptsCallback(
          [
            'Areas/Master/Scripts/Resources/en/res.DayPart.en.js',
            'Areas/Master/Scripts/Resources/en/Types.en.js',
            'Areas/Master/Scripts/Enums.js',
            'Areas/Master/Scripts/DayPart/View.js',
            'Areas/Master/Scripts/Resources/en/res.DayPartDistribution.en.js',
            'Areas/Master/Scripts/DayPartDistribution/View.js',
          ],
          'initDetailMasterDayPart',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadMasterDayPartState'] = (data) => {
    setState(data.Value[0] as IMasterDayPart);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="DayPart" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Dayparts: <span>{state.Display}</span>
                      <span
                        id="DayPartToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.ConversionAudienceId && (
                    <span className="element">
                      <label>
                        Default Demo: <label className="strong">{state.ConversionAudienceDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DayPartCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DayPartLabelBlockedCode">Blocked</label>
                  <input id="DayPartCheckBlockedCode" defaultChecked name="DayPartCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="ConversionAudienceId">Default Demo</label>
                  <select
                    className=""
                    id="DayPartConversionAudienceId"
                    name="ConversionAudienceId"
                    defaultValue={state.ConversionAudienceId}
                  >
                    {state.ConversionAudienceId && (
                      <option value={state.ConversionAudienceId}>{state.ConversionAudienceDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="DayPartAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Segmentation" className="required">
                    Segmentation
                  </label>
                  <select
                    className=""
                    id="DayPartSegmentation"
                    name="Segmentation"
                    value={state.Segmentation}
                    required
                    data-required-msg="Segmentation is required"
                  >
                    <option value="0">All Audiences</option>
                    <option value="1">Kids</option>
                    <option value="2">Adults</option>
                    <option value="3">Sports</option>
                    <option value="4">NA</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="DayPartType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                  >
                    <option value="0">Early Morning</option>
                    <option value="1">Daytime</option>
                    <option value="2">Early Fringe</option>
                    <option value="3">Primetime</option>
                    <option value="4">Late News</option>
                    <option value="5">Late Fringe</option>
                    <option value="6">Overnight</option>
                    <option value="7">Weekend</option>
                    <option value="8">Cable</option>
                    <option value="9">Other</option>
                    <option value="10">NA</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DayPartSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="DayPartSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select className="" id="DayPartSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source}>
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarDayPart">
                <div className="left">
                  <button id="cancelDayPartButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDayPartButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="associationDayPartDayPartDistribution">
            <div className="k-block grid-details">
              <h2>Media Types</h2>
              <div id="DayPartDistributionGrid" className="grid-control"></div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
