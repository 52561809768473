import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingMediaNetworkProfileNewCustomPage(): React.ReactElement {
  const [state, setState] = useState<ITradingMediaNetworkProfile>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'MediaNetworkProfile');
  const updatedState: ITradingMediaNetworkProfile = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.Hispanic = 1;
      updatedState.Multicultural = 1;
      updatedState.DiversityOwnership = 0;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.MediaNetworkProfile.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/MediaNetworkProfile/View.js',
        ],
        'initNewTradingMediaNetworkProfile',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MediaNetworkProfile" className="controls-container default block">
              <h3>
                Create a New <b>Media Network Profiles</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="MediaNetworkProfileCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="MediaNetworkProfileLabelBlockedCode">Blocked</label>
                  <input
                    id="MediaNetworkProfileCheckBlockedCode"
                    defaultChecked
                    name="MediaNetworkProfileCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaGroupId">Media Group</label>
                  <select
                    className=""
                    id="MediaNetworkProfileMediaGroupId"
                    name="MediaGroupId"
                    defaultValue={state.MediaGroupId}
                    required
                    data-required-msg="Media  Group is required"
                  >
                    {state.MediaGroupId && <option value={state.MediaGroupId}>{state.MediaGroupDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="MediaNetworkProfileAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Hispanic" className="required">
                    Hispanic
                  </label>
                  <select
                    className=""
                    id="MediaNetworkProfileHispanic"
                    name="Hispanic"
                    value={state.Hispanic}
                    required
                    data-required-msg="Hispanic is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Multicultural" className="required">
                    Multicultural
                  </label>
                  <select
                    className=""
                    id="MediaNetworkProfileMulticultural"
                    name="Multicultural"
                    value={state.Multicultural}
                    required
                    data-required-msg="Multicultural is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="DiversityOwnership" className="required">Diversity Ownership</label>
                  <select className="" id="MediaNetworkProfileDiversityOwnership" name="DiversityOwnership" value={state.DiversityOwnership} required data-required-msg="Diversity Ownership is required" >
                    <option value="0">-</option>
                    <option value="1">AAPI (Asian American Pacific Islander)</option>
                    <option value="2">Black owned</option>
                    <option value="3">Hispanic owned</option>
                    <option value="4">LGBTQ+ owned</option>
                    <option value="5">Native American</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="MediaNetworkProfileSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarMediaNetworkProfile">
                <div className="left">
                  <button id="cancelMediaNetworkProfileButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveMediaNetworkProfileButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingMediaNetworkProfile {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  MediaGroupId?: string;
  MediaGroupDisplayValue?: string;
  Alias?: string;
  DiversityOwnership?: number,
  Hispanic?: number;
  Multicultural?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ApplianceModeEnum {
  'Yes' = 0,
  'No' = 1,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DiversityOwnershipEnum { '-' = 0, 'AAPI (Asian American Pacific Islander)' = 1, 'Black owned' = 2, 'Hispanic owned' = 3, 'LGBTQ+ owned' = 4, 'Native American' = 5 }

export function TradingMediaNetworkProfileDetailCustomPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingMediaNetworkProfile>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'MediaNetworkProfile');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'MediaNetworkProfile', id).then((result) => {
        setState(result.Value[0] as ITradingMediaNetworkProfile);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.MediaNetworkProfile.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/MediaNetworkProfile/View.custom.js',
            'Areas/Trading/Scripts/Resources/en/res.MediaNetworkProfileDistribution.en.js',
            'Areas/Trading/Scripts/MediaNetworkProfileDistribution/View.js',
            'Areas/Trading/Scripts/MediaNetworkProfileDistribution/View.extended.js',
            'Areas/Trading/Scripts/Resources/en/res.MediaNetworkProfileMap.en.js',
            'Areas/Trading/Scripts/MediaNetworkProfileMap/View.js',
            'Areas/Trading/Scripts/MediaNetworkProfileMap/View.extended.js',
          ],
          'initDetailTradingMediaNetworkProfile',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingMediaNetworkProfileState'] = (data) => {
    setState(data.Value[0] as ITradingMediaNetworkProfile);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MediaNetworkProfile" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Media Network Profiles: <span>{state.Display}</span>
                      <span
                        id="MediaNetworkProfileToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.MediaGroupId && (
                    <span className="element">
                      <label>
                        Media Group: <label className="strong">{state.MediaGroupDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  <span className="element">
                    <label>
                      Hispanic:{' '}
                      <label className="strong">
                        {state.Hispanic != undefined ? ApplianceModeEnum[state.Hispanic] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Multicultural:{' '}
                      <label className="strong">
                        {state.Multicultural != undefined ? ApplianceModeEnum[state.Multicultural] : ''}
                      </label>
                    </label>
                  </span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="MediaNetworkProfileCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="MediaNetworkProfileLabelBlockedCode">Blocked</label>
                  <input
                    id="MediaNetworkProfileCheckBlockedCode"
                    defaultChecked
                    name="MediaNetworkProfileCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaGroupId">Media Group</label>
                  <select
                    className=""
                    id="MediaNetworkProfileMediaGroupId"
                    name="MediaGroupId"
                    defaultValue={state.MediaGroupId}
                    required
                    data-required-msg="Media  Group is required"
                  >
                    {state.MediaGroupId && <option value={state.MediaGroupId}>{state.MediaGroupDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="MediaNetworkProfileAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Hispanic" className="required">
                    Hispanic
                  </label>
                  <select
                    className=""
                    id="MediaNetworkProfileHispanic"
                    name="Hispanic"
                    value={state.Hispanic}
                    required
                    data-required-msg="Hispanic is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Multicultural" className="required">
                    Multicultural
                  </label>
                  <select
                    className=""
                    id="MediaNetworkProfileMulticultural"
                    name="Multicultural"
                    value={state.Multicultural}
                    required
                    data-required-msg="Multicultural is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  " >
                  <label htmlFor="DiversityOwnership" className="required">Diversity Ownership</label>
                  <select className="" id="MediaNetworkProfileDiversityOwnership" name="DiversityOwnership" value={state.DiversityOwnership} required data-required-msg="Diversity Ownership is required" >
                    <option value="0">-</option>
                    <option value="1">AAPI (Asian American Pacific Islander)</option>
                    <option value="2">Black owned</option>
                    <option value="3">Hispanic owned</option>
                    <option value="4">LGBTQ+ owned</option>
                    <option value="5">Native American</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="MediaNetworkProfileSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="MediaNetworkProfileSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="MediaNetworkProfileSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarMediaNetworkProfile">
                <div className="left">
                  <button id="cancelMediaNetworkProfileButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveMediaNetworkProfileButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="MediaNetworkProfileTabstrip">
            <ul>
              <li id="MediaNetworkProfileMediaNetworkProfileDistribution" className="k-active">
                <i className="fas fa-apple-alt"></i>
                <span className="labeltext">Media Types</span>
              </li>
              <li id="MediaNetworkProfileMediaNetworkProfileMap">
                <i className="fas fa-braille"></i>
                <span className="labeltext">Mappings</span>
              </li>
            </ul>
            <div>
              <div id="associationMediaNetworkProfileMediaNetworkProfileDistribution">
                <div className="k-block grid-details">
                  <h2>Media Types</h2>
                  <div id="MediaNetworkProfileDistributionGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
            <div>
              <div id="associationMediaNetworkProfileMediaNetworkProfileMap">
                <div className="k-block grid-details">
                  <h2>Mappings</h2>
                  <div id="MediaNetworkProfileMapGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
