import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScriptsCallback } from '../../helpers/Utils';

export function OptionsTrackerPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AnnualBudget');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.BudgetPeriod.en.js',
          'Areas/Trading/Scripts/Resources/en/res.AnnualBudget.en.js',
          'Areas/Trading/Scripts/Resources/en/res.OptionTrackerLine.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Resources/en/res.Trading.en.custom.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/jquery.shared.custom.js',
          'Areas/Trading/Scripts/AnnualBudget/OptionsTracker.custom.js',
        ],
        'initSearchOptionTrackerIndex',
        {
          canEdit: true,
          canCreate: true,
          canRemove: true,
        },
      );
    }
  }, []);
  return (
    <div className="panelcontent-section" style={{ display: 'none' }}>
      <div id="sidebar-section">
        <i className="fas fa-bars" id="sidebarBar"></i>
        <div className="k-content">
          <h3>Options Tracker</h3>
          <div className="field fullwidth-container">
            <label className="required">Broadcast Year</label>
            <div style={{ width: '100%', display: 'flex' }}>
              <input id="inputPreviousYear" style={{ width: '48%', marginRight: '10px' }} />
              <input id="inputYear" style={{ width: '48%', float: 'right' }} />
            </div>
          </div>
          <div className="field fullwidth-container">
            <label className="required">Agency</label>
            <input id="inputAgencyProfile" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Sector</label>
            <input id="inputSector" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Master Client</label>
            <input id="inputMasterClient" />
          </div>
          <div className="field fullwidth-container">
            <label className="required">Media Group</label>
            <input id="inputMediaGroup" />
          </div>
          <div className="field fullwidth-container">
            <label>Deal Type</label>
            <input id="inputDealType" />
          </div>
          <section className="button-toolbar">
            <div className="right">
              <button id="applyFilterButton" type="button" className="k-button k-primary" title="Go">
                Go
              </button>
            </div>
          </section>
        </div>
      </div>
      <div id="content-section" className="collapsed">
        <i className="fas fa-bars" id="contentBar" style={{ display: 'none' }}></i>
        <div id="OptionTrackerContainer" className="k-content">
          <div id="OptionTrackerLineContent">
            <div id="OptionTrackerLineToolbar"></div>
            <div id="OptionTrackerLineContainer" className="spreadsheet-container">
              <div id="OptionTrackerLineSpreadsheet" style={{ width: '100%' }}></div>
            </div>
          </div>
          <div id="OptionTrackerLineNotify" style={{ width: '100%' }}></div>
        </div>
      </div>
    </div>
  );
}
