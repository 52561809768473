import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function MasterMasterMediaTypeNewPage(): React.ReactElement {
  const [state, setState] = useState<IMasterMasterMediaType>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'MasterMediaType');
  const updatedState: IMasterMasterMediaType = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Master/Scripts/Resources/en/res.MasterMediaType.en.js',
          'Areas/Master/Scripts/Resources/en/Types.en.js',
          'Areas/Master/Scripts/Enums.js',
          'Areas/Master/Scripts/MasterMediaType/View.js',
        ],
        'initNewMasterMasterMediaType',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MasterMediaType" className="controls-container default block">
              <h3>
                Create a New <b>Master Media Type</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="MasterMediaTypeCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="MasterMediaTypeLabelBlockedCode">Blocked</label>
                  <input
                    id="MasterMediaTypeCheckBlockedCode"
                    defaultChecked
                    name="MasterMediaTypeCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="MasterMediaTypeEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('MasterMediaTypeEnglishName', 'MasterMediaTypeNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="MasterMediaTypeNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="MasterMediaTypeEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() =>
                      window['OnChangeReply']('MasterMediaTypeEnglishShortName', 'MasterMediaTypeNativeShortName')
                    }
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="MasterMediaTypeNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Notes">Notes</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="MasterMediaTypeNotes"
                      name="Notes"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Notes}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="MasterMediaTypeSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarMasterMediaType">
                <div className="left">
                  <button id="cancelMasterMediaTypeButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveMasterMediaTypeButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterMasterMediaType {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  EnglishName?: string;
  EnglishShortName?: string;
  NativeName?: string;
  NativeShortName?: string;
  Notes?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function MasterMasterMediaTypeDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterMasterMediaType>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'MasterMediaType');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Master', 'MasterMediaType', id).then((result) => {
        setState(result.Value[0] as IMasterMasterMediaType);
        appendScriptsCallback(
          [
            'Areas/Master/Scripts/Resources/en/res.MasterMediaType.en.js',
            'Areas/Master/Scripts/Resources/en/Types.en.js',
            'Areas/Master/Scripts/Enums.js',
            'Areas/Master/Scripts/MasterMediaType/View.js',
          ],
          'initDetailMasterMasterMediaType',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadMasterMasterMediaTypeState'] = (data) => {
    setState(data.Value[0] as IMasterMasterMediaType);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="MasterMediaType" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Master Media Type: <span>{state.Display}</span>
                      <span
                        id="MasterMediaTypeToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line"></div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="MasterMediaTypeCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="MasterMediaTypeLabelBlockedCode">Blocked</label>
                  <input
                    id="MasterMediaTypeCheckBlockedCode"
                    defaultChecked
                    name="MasterMediaTypeCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="MasterMediaTypeEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('MasterMediaTypeEnglishName', 'MasterMediaTypeNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="MasterMediaTypeNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="MasterMediaTypeEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() =>
                      window['OnChangeReply']('MasterMediaTypeEnglishShortName', 'MasterMediaTypeNativeShortName')
                    }
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="MasterMediaTypeNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Notes">Notes</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="MasterMediaTypeNotes"
                      name="Notes"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Notes}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="MasterMediaTypeSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="MasterMediaTypeSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="MasterMediaTypeSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarMasterMediaType">
                <div className="left">
                  <button id="cancelMasterMediaTypeButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveMasterMediaTypeButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
