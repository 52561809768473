import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScripts } from '../../helpers/Utils';

export function DirectResponseUploadPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AnnualBudget');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScripts(['Areas/Trading/Scripts/AnnualBudget/DirectResponseUpload.custom.js']);
    }
  }, []);

  return (
    <div id="UploadContent" className="container-fluid">
      <form id="form" className="content-view row">
        <h2 style={{ paddingTop: '1em' }}>Direct Response Upload</h2>
        <div className="clear"></div>
        <p>
          Please select the files to be uploaded by clicking on &quot;Select&quot; button. Once ready proceed to click
          on &quot;Upload&quot; button and wait until the process was completed.
        </p>
        <div className="col-md-4 shortcontrol-container">
          <label>File Type</label>
          <input id="ReportTypes" type="text" />
        </div>
        <div className="clear"></div>
        <section className="button-toolbar" id="ToolbarAnnualBudget">
          <div className="right">
            <button id="editAnnualBudgetButton" type="button" disabled className="k-button button" title="Select File">
              Select File
            </button>
          </div>
        </section>
      </form>
    </div>
  );
}
