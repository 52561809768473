import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

interface ITradingClientProfile {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  AgencyId?: string;
  AgencyDisplayValue?: string;
  AudienceId?: string;
  AudienceDisplayValue?: string;
  MasterClientId?: string;
  MasterClientDisplayValue?: string;
  SectorId?: string;
  SectorDisplayValue?: string;
  Alias?: string;
  Broadcast?: boolean;
  Calendar?: boolean;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function TradingClientProfileDetailCustomPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingClientProfile>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'ClientProfile');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'ClientProfile', id).then((result) => {
        setState(result.Value[0] as ITradingClientProfile);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.ClientProfile.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/ClientProfile/View.custom.js',
            'Areas/Trading/Scripts/Resources/en/res.ClientProfileMap.en.js',
            'Areas/Trading/Scripts/ClientProfileMap/View.js',
            'Areas/Trading/Scripts/ClientProfileMap/View.extended.js',
          ],
          'initDetailTradingClientProfile',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingClientProfileState'] = (data) => {
    setState(data.Value[0] as ITradingClientProfile);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ClientProfile" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Client Profiles: <span>{state.Display}</span>
                      <span
                        id="ClientProfileToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.AgencyId && (
                    <span className="element">
                      <label>
                        Agency: <label className="strong">{state.AgencyDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.AudienceId && (
                    <span className="element">
                      <label>
                        Default Demo: <label className="strong">{state.AudienceDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MasterClientId && (
                    <span className="element">
                      <label>
                        Master Client: <label className="strong">{state.MasterClientDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
                <div className="content-line">
                  {state.SectorId && (
                    <span className="element">
                      <label>
                        Sector: <label className="strong">{state.SectorDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="ClientProfileCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="ClientProfileLabelBlockedCode">Blocked</label>
                  <input
                    id="ClientProfileCheckBlockedCode"
                    defaultChecked
                    name="ClientProfileCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AgencyId">Agency</label>
                  <select
                    className=""
                    id="ClientProfileAgencyId"
                    name="AgencyId"
                    defaultValue={state.AgencyId}
                    required
                    data-required-msg="Agency is required"
                  >
                    {state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AudienceId">Default Demo</label>
                  <select className="" id="ClientProfileAudienceId" name="AudienceId" defaultValue={state.AudienceId}>
                    {state.AudienceId && <option value={state.AudienceId}>{state.AudienceDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterClientId">Master Client</label>
                  <select
                    className=""
                    id="ClientProfileMasterClientId"
                    name="MasterClientId"
                    defaultValue={state.MasterClientId}
                    required
                    data-required-msg="Master Client is required"
                  >
                    {state.MasterClientId && (
                      <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="SectorId">Sector</label>
                  <select className="" id="ClientProfileSectorId" name="SectorId" defaultValue={state.SectorId}>
                    {state.SectorId && <option value={state.SectorId}>{state.SectorDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="ClientProfileAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Broadcast">Broadcast</label>
                  <input
                    type="checkbox"
                    id="ClientProfileBroadcast"
                    name="Broadcast"
                    defaultChecked={state.Broadcast}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Calendar">Calendar</label>
                  <input type="checkbox" id="ClientProfileCalendar" name="Calendar" defaultChecked={state.Calendar} />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="ClientProfileSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="ClientProfileSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="ClientProfileSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarClientProfile">
                <div className="left">
                  <button id="cancelClientProfileButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveClientProfileButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="associationClientProfileClientProfileMap">
            <div className="k-block grid-details">
              <h2>Mappings</h2>
              <div id="ClientProfileMapGrid" className="grid-control"></div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
