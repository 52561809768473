import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { RootState } from '../../store/reducer/rootReducer';
import { sidebar_menu } from '../../config/Menu';
export function useSecurityProfile(): any {
  const selectProfile = (state: RootState) => state.security.profile;
  const currentProfile = useSelector(selectProfile);
  return {
    profile: currentProfile,
  };
}

export function useSecurity(bc: string, ent: string): any {
  const selectProfile = (state: RootState) => state.security.profile;
  const currentProfile = useSelector(selectProfile);
  const navigate = useNavigate();
  const location = useLocation();
  const menu = sidebar_menu;
  const checkAccess = () => {
    if (currentProfile?.State != 3) {
      navigate('/');
      return false;
    }
    if (bc != 'Shared') {
      const operationPermission = currentProfile?.DataAuth.OperationPermission;
      if (
        !operationPermission?.some(
          (x) => x.BoundedContext == '' || (x.BoundedContext == bc && (x.Entity == '' || x.Entity == ent)),
        )
      ) {
        navigate('/');
        return false;
      }
    }

    const topMenuItems = currentProfile?.DataAuth.TopMenuItems;
    const filterData = menu.filter(
      (e) =>
        e.parentId === undefined &&
        topMenuItems?.some(
          (t) => t.Item1 == '' || t.Item1 == e.key || (t.Item1 == 'Framework' && (t.Item2 == e.key || t.Item2 == '')),
        ),
    );
    const locationPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));

    if (locationPath == '/Framework/Audit') return true;

    const children = sidebar_menu.filter((e) =>
      filterData.some(
        (x) =>
          e.parentId === x.id &&
          topMenuItems?.some(
            (t) =>
              t.Item1 == '' ||
              ((t.Item1 == x.key || (t.Item1 == 'Framework' && (t.Item2 == x.key || t.Item2 == ''))) &&
                (t.Item3 == '' || t.Item3 == e.key)),
          ),
      ),
    );
    //console.log(topMenuItems);
    //console.log(filterData);
    //console.log(children);
    //console.log(location.pathname);
    //console.log(locationPath);

    if (
      !children.some(
        (x) =>
          x.route.toLowerCase() == location.pathname.toLowerCase() ||
          x.route.replace('/Index', '').toLowerCase() == locationPath.toLowerCase() ||
          x.route.toLowerCase() == locationPath.toLowerCase(),
      )
    ) {
      navigate('/');
      return false;
    }
    return true;
  };
  return {
    profile: currentProfile,
    checkAccess: checkAccess,
    //canUpdate: canUpdate(mapProfile()),
    //canDelete: canDelete(mapProfile()),
    //canView: canView(mapProfile()),
    //canCreate: canCreate(mapProfile()),
    //canDuplicate: canDuplicate(mapProfile()),
  };
}
